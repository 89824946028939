import React from "react";
import { Grid, Button, Message, Checkbox } from "semantic-ui-react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import actions from "actions";
import api from "api";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { useAppSelector } from "store";

const OrgTagChooser = ({ setVisible }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useAppSelector((state) => state.user);

  const onUpdateTags = (tags) => dispatch(actions.user.updateTags(tags));

  const toggleTag = (tag) => {
    const { fullTags = [], tags = [] } = user;
    const isTagged = tags.indexOf(tag._id) !== -1;

    if (isTagged) {
      api.tags.untagSingle(
        "users",
        tag._id,
        user._id,
        (newTag) => {
          const updatedTags = Object.assign([], fullTags);
          updatedTags.splice(
            fullTags.findIndex((ft) => ft._id === newTag._id),
            1,
          );
          onUpdateTags(updatedTags);
        },
        () => {
          toast.error("Failed to deselect tag");
        },
      );
    } else {
      api.tags.tagSingle(
        "users",
        tag._id,
        user._id,
        (newTag) => {
          const updatedTags = Object.assign([], fullTags);
          updatedTags.push(newTag);
          onUpdateTags(updatedTags);
        },
        (err) => toast.error(err.message),
      );
    }
  };

  const deselectAll = () => {
    const tagIds = user.organisation.tagList.map((t) => t._id);
    api.tags.untagDocuments(
      "users",
      tagIds,
      [user._id],
      () => {
        onUpdateTags([]);
      },
      () => {
        toast.error("Failed to deselect tags");
      },
    );
  };

  const selectAll = () => {
    const tagIds = user.organisation.tagList.map((t) => t._id);
    api.tags.tagDocuments(
      "users",
      tagIds,
      [user._id],
      (fullTags) => {
        onUpdateTags(fullTags);
      },
      () => {
        toast.error("Failed to select tags");
      },
    );
  };

  const tags = user?.tags || [];
  const selected = user?.organisation?.tagList.length === tags?.length;
  return (
    <div>
      <Helmet title={t("tags.title")} />
      <Grid stackable centered style={{ marginTop: 40, marginBottom: 20 }}>
        <Grid.Column computer={10} tablet={14}>
          <h1>
            <span role="img" aria-label="Wave">
              👋
            </span>{" "}
            {t("tags.hi", { name: user?.profile?.firstName })}
          </h1>
          <h3>{t("tags.selectTags")}</h3>
          <p>{t("tags.info", { orgName: user?.organisation?.name })}</p>
          <Checkbox
            toggle
            style={{ float: "right" }}
            label={selected ? "Deselect all" : "Select All"}
            checked={selected}
            onChange={() => (selected ? deselectAll() : selectAll())}
          />
          <Grid stackable columns={2} style={{ marginTop: 20, marginBottom: 20, clear: "both" }}>
            {user?.organisation?.tagList
              ? user.organisation.tagList.map((c) => (
                  <Grid.Column key={c._id}>
                    <Checkbox toggle checked={tags.indexOf(c._id) > -1} onChange={() => toggleTag(c)} label={c.value} />
                  </Grid.Column>
                ))
              : null}
          </Grid>
          {tags?.length ? (
            <Button
              onClick={() => {
                setVisible(false);
                window.scrollTo(0, 0);
              }}
              primary
              content={t("generic.continue")}
            />
          ) : (
            <Message info>{t("tags.chooseMany")}</Message>
          )}
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default OrgTagChooser;
