import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Loader, Button, Modal, Divider, Input, Checkbox, Popup, Tab, Form, Icon, Message } from "semantic-ui-react";
import styled from "styled-components";
import util from "utils/utils";
import { Other } from "simplydo/interfaces";
import RoleEditorPermissions, {
  PermissionDiv,
  PermissionDetails,
  PermissionCheckbox,
  PermissionTitle,
  PermissionMeta,
} from "./Permissions";
import RoleTable from "components/lib/Roles/RoleTable";
import toast from "react-hot-toast";
import api from "api";

import RoleEditorAssignment from "./Assignment";
import RoleEditorIconChooser from "./IconChooser";
import { CheckboxHeader } from "components/lib/UI";

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: ${({ theme }) => (theme.sizes.isMobile ? "column" : "row")};
  margin-bottom: 10px;
  gap: 10px;
`;

type RoleEditorProps = {
  forType: "organisation" | "group" | "global" | "ideaBusinessProfile";
  permissionOptions: { key: string; text: string; title: string }[];
  forId: string;
  canCreateNewRoles?: boolean;
  canSetRolesAsDefault?: boolean;
};

const typeNames = {
  organisation: "organisation",
  group: "group",
  global: "global",
  ideaBusinessProfile: "business profile",
};

const RoleEditor = ({
  forType,
  forId,
  permissionOptions,
  canCreateNewRoles,
  canSetRolesAsDefault,
}: RoleEditorProps) => {
  // Updating roles state
  const [roles, setRoles] = useState<Other.IRole[]>([]);
  const [managingPermissionsForRoleModalId, setManagingPermissionsForRoleModalId] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [deletingRole, setDeletingRole] = useState<string>("");
  const [updatedRoleName, setUpdatedRoleName] = useState<string>("");

  const [roleNameSearch, setRoleNameSearch] = useState("");
  const [rolePermissionSearch, setRolePermissionSearch] = useState("");

  const filteredRoles = useMemo(() => {
    const nameSearch = roleNameSearch.toLowerCase();
    const permissionSearch = rolePermissionSearch.toLowerCase();

    if (!nameSearch && !permissionSearch) {
      return roles;
    }

    return roles.filter((role) => {
      const nameMatch = role.name.toLowerCase().includes(nameSearch);
      if (nameSearch && !nameMatch) {
        return false;
      }
      if (!permissionSearch) {
        return true;
      }
      for (const permission of role.permissions) {
        if (permission.toLowerCase().includes(permissionSearch)) {
          return nameMatch;
        }
        if (
          permissionOptions
            .find((perm) => perm.key === permission)
            ?.title.toLowerCase()
            .includes(permissionSearch)
        ) {
          return nameMatch;
        }
      }

      return false;
    });
  }, [roleNameSearch, permissionOptions, rolePermissionSearch, roles]);

  // Creating new role state
  const [roleCreationModalOpen, setRoleCreationModalOpen] = useState<boolean>(false);
  const [creatingRole, setCreatingRole] = useState<boolean>(false);
  const [newRoleName, setNewRoleName] = useState<string>("");
  const [newRolePermissions, setNewRolePermissions] = useState<string[]>([]);

  // Setting default role state
  const [_settingDefaultRole, setSettingDefaultRole] = useState<string>("");

  const usingForId = forType === "global" ? "global" : forId;
  const managingRoleModalRole = useMemo(
    () => roles.find((role) => role._id === managingPermissionsForRoleModalId),
    [managingPermissionsForRoleModalId, roles],
  );

  const getRoles = useCallback(() => {
    setLoading(true);
    api.roles.get(
      forType,
      usingForId,
      {},
      (data) => {
        setRoles(data.roles);
        setLoading(false);
      },
      (err) => {
        toast.error(err.message);
        setLoading(false);
      },
    );
  }, [usingForId, forType]);

  const createRole = useCallback(() => {
    setCreatingRole(true);
    api.roles.createRole(
      forType,
      usingForId,
      {
        name: newRoleName,
        permissions: newRolePermissions,
      },
      ({ role: newRole }) => {
        setCreatingRole(false);
        setRoles((prevRoles) => [newRole, ...prevRoles]);
        setNewRoleName("");
        setNewRolePermissions([]);
        setRoleCreationModalOpen(false);
      },
      (err) => {
        toast.error(err.message);
        setCreatingRole(false);
      },
    );
  }, [forType, newRoleName, newRolePermissions, usingForId]);

  const deleteRole = useCallback(
    (roleId: string) => {
      util
        .confirm(
          "Delete role",
          `Are you sure you want to delete the role "${roles.find((role) => role._id === roleId).name}"? This is permanent and cannot be undone. All users assigned to this role will have the role revoked and the role will be deleted.`,
        )
        .then(() => {
          setDeletingRole(roleId);
          api.roles.deleteRole(
            forType,
            usingForId,
            roleId,
            () => {
              setManagingPermissionsForRoleModalId("");
              setDeletingRole("");
              setRoles((prevRoles) => prevRoles.filter((role) => role._id !== roleId));
            },
            (err) => {
              toast.error(err.message);
              setDeletingRole("");
            },
          );
        })
        .catch(() => {});
    },
    [forType, roles, usingForId],
  );

  const setRoleAsDefault = useCallback(
    (roleId: string) => {
      const usingRole = roles.find((role) => role._id === roleId);
      util
        .confirm(
          usingRole.isDefaultRole ? "Remove as default role" : "Add as default role",
          usingRole.isDefaultRole
            ? `Are you sure you want to remove "${usingRole?.name}" as a default role for new users? This will not affect existing users.`
            : `Are you sure you want to add "${usingRole?.name}" as a default role for new users? All users who join your ${typeNames[forType]} will be granted this role, please be mindful of the level of permissions granted. This will not affect existing users.`,
        )
        .then(() => {
          const apiFunc = usingRole.isDefaultRole ? api.roles.removeDefaultRole : api.roles.addDefaultRole;
          setSettingDefaultRole(roleId);
          apiFunc(
            forType,
            usingForId,
            roleId,
            () => {
              setSettingDefaultRole("");
              setRoles((prevRoles) =>
                prevRoles.map((role) => {
                  if (role._id === roleId) {
                    return {
                      ...role,
                      isDefaultRole: role.isDefaultRole ? false : true,
                    };
                  }
                  return role;
                }),
              );
            },
            (err) => {
              toast.error(err.message);
              setSettingDefaultRole("");
            },
          );
        });
    },
    [roles, forType, usingForId],
  );

  useEffect(() => {
    getRoles();
  }, [getRoles]);

  const updateRole = useCallback(
    (data: Partial<Other.IRole>) => {
      api.roles.updateRole(
        forType,
        usingForId,
        managingPermissionsForRoleModalId,
        data,
        ({ role }) => {
          toast.success("Role updated");
          setRoles((prevRoles) => {
            const newRoles = [...prevRoles];
            const index = newRoles.findIndex((role) => role._id === managingPermissionsForRoleModalId);
            newRoles[index] = role;
            return newRoles;
          });
        },
        (err) => {
          toast.error(err.message);
        },
      );
    },
    [forType, managingPermissionsForRoleModalId, usingForId],
  );

  const saveRoleName = useCallback(() => {
    updateRole({ name: updatedRoleName });
  }, [updateRole, updatedRoleName]);

  return (
    <div>
      <Modal
        mountNode={document.getElementById("semantic-modal-mount-node")}
        open={roleCreationModalOpen}
        onClose={() => setRoleCreationModalOpen(false)}
      >
        <Modal.Header>Create new role</Modal.Header>
        <Modal.Content scrolling>
          <Input placeholder="Role name" value={newRoleName} onChange={(e) => setNewRoleName(e.target.value)} fluid />
          <Divider hidden />
          {permissionOptions.map((permission) => (
            <PermissionDiv key={permission.key}>
              <PermissionCheckbox>
                <Checkbox
                  toggle
                  checked={newRolePermissions.includes(permission.key)}
                  onChange={() =>
                    setNewRolePermissions((prevPermissions) => {
                      if (prevPermissions.includes(permission.key)) {
                        return prevPermissions.filter((perm) => perm !== permission.key);
                      }
                      return [...prevPermissions, permission.key];
                    })
                  }
                />
              </PermissionCheckbox>
              <PermissionDetails>
                <PermissionTitle>{permission.title}</PermissionTitle>
                <PermissionMeta>{permission.text}</PermissionMeta>
              </PermissionDetails>
            </PermissionDiv>
          ))}
        </Modal.Content>
        <Modal.Actions>
          <Button content="Cancel" onClick={() => setRoleCreationModalOpen(false)} />
          <Button
            content="Create role"
            icon="plus"
            primary
            onClick={createRole}
            loading={creatingRole}
            disabled={!newRoleName}
          />
        </Modal.Actions>
      </Modal>

      <Modal
        mountNode={document.getElementById("semantic-modal-mount-node")}
        open={!!managingPermissionsForRoleModalId}
        onClose={() => setManagingPermissionsForRoleModalId("")}
      >
        <Modal.Header>
          Managing role "{managingRoleModalRole?.name}"{" "}
          {managingRoleModalRole?.icon?.name ? (
            <Icon name={managingRoleModalRole.icon.name} color={managingRoleModalRole.icon.colour} />
          ) : null}
        </Modal.Header>
        <Modal.Content
          scrolling
          style={{
            minHeight: "80vh",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Form>
            <Form.Input
              label="Role name"
              placeholder="Role name"
              value={updatedRoleName || managingRoleModalRole?.name}
              onChange={(e) => setUpdatedRoleName(e.target.value)}
              fluid
              action={<Button primary content="Save" onClick={() => saveRoleName()} />}
            />
          </Form>
          {forType === "organisation" ? (
            <>
              <Divider hidden />
              <span>
                <b>Icon</b>
              </span>
              <span>
                Role icons are displayed next to users names across SimplyDo, if they have this role assigned to them.
              </span>
              <RoleEditorIconChooser
                containerStyle={{ margin: "10px 0 0" }}
                role={managingRoleModalRole}
                updateRole={updateRole}
              />
            </>
          ) : null}
          <Divider hidden />
          {managingRoleModalRole?.preventUserEditing ? (
            <div>
              <Message info>
                <Icon name="info" />
                This role is a system role, its permissions cannot be edited. It is required for the system to function
                correctly.
              </Message>
              <Divider hidden />
            </div>
          ) : null}
          {canSetRolesAsDefault && !managingRoleModalRole?.preventUserEditing ? (
            <>
              <Popup
                hoverable
                content="This role will be assigned to every user by default."
                trigger={
                  <CheckboxHeader
                    header="Set as default role"
                    description={`Every user will be automatically assigned this role upon joining the ${typeNames[forType]}.`}
                    as="h5"
                    checked={managingRoleModalRole?.isDefaultRole}
                    onChange={() => setRoleAsDefault(managingPermissionsForRoleModalId)}
                  />
                }
              />
            </>
          ) : null}
          <Tab
            menu={{ secondary: true, pointing: true }}
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
            }}
            panes={[
              {
                menuItem: "Permissions",
                render: () => (
                  <RoleEditorPermissions
                    forType={forType}
                    forId={usingForId}
                    permissionOptions={permissionOptions}
                    usingRole={managingRoleModalRole}
                    setRoles={setRoles}
                  />
                ),
              },
              {
                menuItem: "Assignment",
                render: () => (
                  <RoleEditorAssignment
                    forType={forType}
                    forId={usingForId}
                    usingRole={managingRoleModalRole}
                    setRoles={setRoles}
                  />
                ),
              },
            ]}
          />
        </Modal.Content>
        <Modal.Actions>
          {!managingRoleModalRole?.preventUserDeletion ? (
            <Button
              content="Delete role"
              icon="trash"
              basic
              onClick={() => deleteRole(managingPermissionsForRoleModalId)}
              loading={deletingRole === managingPermissionsForRoleModalId}
            />
          ) : null}

          <Button content="Done" onClick={() => setManagingPermissionsForRoleModalId("")} />
        </Modal.Actions>
      </Modal>

      <FilterContainer>
        {canCreateNewRoles ? (
          <Button
            content="Create new role"
            icon="plus"
            primary
            fluid
            style={{ maxWidth: 200 }}
            onClick={() => setRoleCreationModalOpen(true)}
          />
        ) : null}

        <Input
          icon="search"
          placeholder="Search by name..."
          value={roleNameSearch}
          onChange={(e) => setRoleNameSearch(e.target.value)}
        />
        <Input
          icon="search"
          placeholder="Search by permission..."
          value={rolePermissionSearch}
          onChange={(e) => setRolePermissionSearch(e.target.value)}
        />
      </FilterContainer>

      {loading ? (
        <Loader active inline="centered" />
      ) : (
        <RoleTable
          roles={filteredRoles}
          permissionOptions={permissionOptions}
          getRoleActions={(role) => (
            <Button content="Manage" icon="cogs" onClick={() => setManagingPermissionsForRoleModalId(role._id)} />
          )}
        />
      )}
    </div>
  );
};

export default RoleEditor;
