import api from "api";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Button, Icon, Image, Input, Message, Modal, Pagination, Table } from "semantic-ui-react";
import { useAppSelector } from "store";
import useTheme from "theme/useTheme";
import util from "utils/utils";

type GroupUserManagementModalProps = {
  users: string[];
  onClose: () => void;
};

export const GroupUserManagementModal = ({ users, onClose }: GroupUserManagementModalProps) => {
  const user = useAppSelector((state) => state.user);
  const [query, setQuery] = useState("");
  const [page, setPage] = useState(1);
  const limitPerPage = 10;
  const theme = useTheme();

  const [groupMemberships, setGroupMemberships] = useState({});
  useEffect(() => {
    let mounted = true;

    api.groups.getMemberships(
      ({ memberships }) => {
        if (!mounted) {
          return;
        }
        setGroupMemberships(memberships);
      },
      () => {
        if (!mounted) {
          return;
        }
        setGroupMemberships({});
      },
    );

    return () => {
      mounted = false;
    };
  }, []);

  if (!util.hasPermission(user, "org.addToGroupsDirectly", user?.organisation?._id)) {
    return null;
  }

  const groupsWithPermissions = user?.groups.filter(
    (g) =>
      util.hasPermission(user, "group.editSettings", g._id) &&
      util.hasPermission(user, "group.listUsers", g._id) &&
      g.name.toLowerCase().includes(query.toLowerCase()),
  );
  const groupsOnPage = groupsWithPermissions.slice((page - 1) * limitPerPage, page * limitPerPage);

  return (
    <Modal
      mountNode={document.getElementById("semantic-modal-mount-node")}
      open={users && users.length > 0}
      onClose={onClose}
    >
      <Modal.Header>
        <Icon name="users" /> Add to one of my groups
      </Modal.Header>
      <Modal.Content scrolling>
        <p>
          Choose a group to add the selected {util.pluralise(users.length, "user", "users", false)} to. This list only
          shows you the groups where you can manage users.
        </p>

        {query || groupsWithPermissions ? (
          <>
            <Input
              placeholder="Search for a group"
              icon="search"
              value={query}
              onChange={(e, { value }) => setQuery(value)}
            />
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell>Group</Table.HeaderCell>
                  <Table.HeaderCell>Actions</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {groupsOnPage.map((group) => {
                  const allUsersInGroup = users.every((joinUserId) =>
                    groupMemberships[group._id]?.includes(joinUserId),
                  );

                  return (
                    <Table.Row key={group._id}>
                      <Table.Cell collapsing>
                        <Image
                          size="mini"
                          src={util.groupCoverImage(group)}
                          style={{ background: theme.primaryColour, objectFit: "contain" }}
                        />
                      </Table.Cell>
                      <Table.Cell>{group.name}</Table.Cell>
                      <Table.Cell>
                        <Button
                          icon={allUsersInGroup ? "remove user" : "add user"}
                          content={
                            allUsersInGroup
                              ? `Remove ${util.pluralise(users.length, "user", "users")}`
                              : `Add  ${util.pluralise(users.length, "user", "users")}`
                          }
                          color={allUsersInGroup ? "red" : "green"}
                          size="small"
                          onClick={() => {
                            const func = allUsersInGroup ? api.groups.bulkLeave : api.groups.bulkJoin;
                            func(
                              group._id,
                              {
                                users,
                              },
                              () => {
                                toast.success(
                                  allUsersInGroup
                                    ? `Removed ${util.pluralise(users.length, "user", "users")} from ${group.name}`
                                    : `Added ${util.pluralise(users.length, "user", "users")} to ${group.name}`,
                                );
                                setGroupMemberships({
                                  ...groupMemberships,
                                  [group._id]: allUsersInGroup
                                    ? groupMemberships[group._id].filter((u) => !users.includes(u))
                                    : [...(groupMemberships[group._id] || []), ...users],
                                });
                              },
                              () => {},
                            );
                          }}
                        />
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
            {groupsWithPermissions.length / limitPerPage > 1 ? (
              <Pagination
                page={page}
                onPageChange={(e, { activePage }) => setPage(activePage as number)}
                totalPages={Math.ceil(groupsWithPermissions.length / limitPerPage)}
              />
            ) : null}
          </>
        ) : (
          <Message info>
            You currently do not have the required permissions to add users directly to any of your groups. To add users
            you need to have the "Edit settings" and "List users" permissions in your group role.
          </Message>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button primary icon="check" content="Done" onClick={onClose} />
      </Modal.Actions>
    </Modal>
  );
};
