import React, { useCallback, useMemo } from "react";
import { List, Popup, CardGroupProps, Label, Table, Icon } from "semantic-ui-react";
import { Other } from "simplydo/interfaces";

type IRole = Other.IRole;
type IRolePermissionConstant = Other.IRolePermissionConstant;

type RoleTableProps = CardGroupProps & {
  roles: IRole[];
  permissionOptions: IRolePermissionConstant[];
  getPermissionActions?: (roleId: string, permission: IRolePermissionConstant) => React.ReactNode;
  getRoleActions?: (role: IRole) => React.ReactNode;
};

const RoleTable = ({ roles, permissionOptions, getPermissionActions, getRoleActions }: RoleTableProps) => {
  const getPermissionDetails = useCallback(
    (permissions: string[]) => permissionOptions.filter((permission) => permissions.includes(permission.key)),
    [permissionOptions],
  );
  const hasRoleWithMoreThanTenPermissions = useMemo(
    () => !!roles.find((role) => role.permissions.length > 10),
    [roles],
  );

  return (
    <div style={{ maxWidth: "100%", overflow: "auto" }}>
      <Table unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell>Role</Table.HeaderCell>
            <Table.HeaderCell>Permissions</Table.HeaderCell>
            {hasRoleWithMoreThanTenPermissions ? <Table.HeaderCell></Table.HeaderCell> : null}
            <Table.HeaderCell>Users</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {roles.map((role) => {
            const roleActions = getRoleActions ? getRoleActions(role) : null;

            return (
              <Table.Row key={role._id}>
                <Table.Cell collapsing>
                  <div style={{ display: "flex", flexDirection: "row", gap: 5, alignItems: "center" }}>
                    {role.icon ? (
                      <Popup
                        trigger={<Icon name={role.icon.name} size="large" color={role.icon.colour} />}
                        content={`Custom icon for this role. This icon displays next to users' names in SimplyDo if they have this role. You can change this by clicking "Manage".`}
                      />
                    ) : null}
                    {role.isDefaultRole ? (
                      <Popup
                        trigger={
                          <Label size="mini" color="blue">
                            Default role
                          </Label>
                        }
                        content="This role is a default role for new users. It will be automatically assigned to users upon joining."
                      />
                    ) : null}
                  </div>
                </Table.Cell>
                <Table.Cell>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ flex: 1 }}>{role.name}</div>
                    {role.ownerScopes?.organisations?.length ? (
                      <p>
                        <span>
                          Organisation specific functionality in this role is limited to the following organisations:
                        </span>
                        <List>
                          {role.ownerScopes.organisations.map((org) => (
                            <List.Item key={org._id}>
                              <List.Content>
                                <List.Header>- {org.name}</List.Header>
                              </List.Content>
                            </List.Item>
                          ))}
                        </List>
                      </p>
                    ) : null}
                  </div>
                </Table.Cell>
                <Table.Cell verticalAlign="top">
                  <List>
                    {getPermissionDetails(role.permissions.slice(0, 10)).map((permission) => {
                      const permissionActions = getPermissionActions
                        ? getPermissionActions(role._id, permission)
                        : null;
                      return (
                        <List.Item key={permission.key} style={{ whiteSpace: "nowrap" }}>
                          {permissionActions ? <List.Content floated="right">{permissionActions}</List.Content> : null}
                          <Popup
                            content={permission.text}
                            trigger={
                              <List.Content>
                                <List.Header>- {permission.title}</List.Header>
                              </List.Content>
                            }
                          />
                        </List.Item>
                      );
                    })}
                  </List>
                </Table.Cell>
                {hasRoleWithMoreThanTenPermissions ? (
                  <Table.Cell verticalAlign="top">
                    <List>
                      {getPermissionDetails(role.permissions.slice(10)).map((permission) => {
                        const permissionActions = getPermissionActions
                          ? getPermissionActions(role._id, permission)
                          : null;
                        return (
                          <List.Item key={permission.key} style={{ whiteSpace: "nowrap" }}>
                            {permissionActions ? (
                              <List.Content floated="right">{permissionActions}</List.Content>
                            ) : null}
                            <Popup
                              content={permission.text}
                              trigger={
                                <List.Content>
                                  <List.Header>- {permission.title}</List.Header>
                                </List.Content>
                              }
                            />
                          </List.Item>
                        );
                      })}
                    </List>
                  </Table.Cell>
                ) : null}
                <Table.Cell collapsing>{role.userCount ?? 0}</Table.Cell>
                <Table.Cell collapsing>{roleActions ? roleActions : null}</Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </div>
  );
};

export default RoleTable;
