import React, { useState } from "react";
import { Link } from "react-router-dom";
import util from "utils/utils";

import RowComponent from "components/lib/RowComponent";
import { ViewMore } from "./Containers";

function HomeProjectAssignedIdeas({ assignments: ideas }) {
  const [showMore, setShowMore] = useState(false);

  if (!ideas?.length) return null;
  return (
    <>
      <RowComponent $noBorder>
        <RowComponent.Fill>
          <RowComponent.Title>Your project assignments</RowComponent.Title>
          <div />
        </RowComponent.Fill>
        {ideas.slice(0, showMore ? 10 : 4).map((idea, index) => (
          <RowComponent.Cell
            index={index}
            key={idea._id}
            as={Link}
            to={`/challenges/${idea.challenge}/board?idea=${idea._id}`}
          >
            <div
              style={{
                width: 50,
                height: 40,
                borderRadius: 3,
                flex: "0 0 50px",
                backgroundSize: "cover",
                backgroundPosition: "center center",
                backgroundImage: `url(${util.mixinCssUrlFallback(util.ideaCoverImage(idea, "200x200"), util.ideaCoverImage())})`,
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: 5,
                marginRight: 5,
              }}
            >
              <RowComponent.Title wrapping style={{ color: "#5d5d5d" }} block>
                {idea.name}
              </RowComponent.Title>
              <RowComponent.StatusArea>
                <RowComponent.StatusLabel color="blue" />
                Project stage: <b style={{ marginLeft: 3 }}>{idea.projectLane || "Default"}</b>
              </RowComponent.StatusArea>
            </div>
          </RowComponent.Cell>
        ))}
        {ideas.length > 4 ? (
          <>
            {showMore ? (
              <ViewMore onClick={() => setShowMore(false)}>Hide</ViewMore>
            ) : (
              <ViewMore onClick={() => setShowMore(true)}>View more</ViewMore>
            )}
          </>
        ) : null}
      </RowComponent>
    </>
  );
}

export default HomeProjectAssignedIdeas;
