import React, { useState, useCallback, ReactElement, Fragment, cloneElement, useMemo } from "react";
import { Modal, Button, List, Header, Dropdown, Message } from "semantic-ui-react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Other } from "simplydo/interfaces";
import actions from "actions";
import util from "utils/utils";
import moment from "moment";
import { DisabledExplanationPopup } from "./UI";
import { useTranslation } from "react-i18next";

interface IChallengeStageChanger {
  challengeId: string;
  challenge?: Other.IChallenge;
  user: Other.IUserMe;
  trigger: ReactElement;
  history: History;
  match: {
    params: {
      id: string;
    };
  };
  updateStage: (newStage: string, openWithComms?: boolean) => void;
}

const ChallengeStageChanger = ({ challenge, trigger, updateStage, user }: IChallengeStageChanger) => {
  const navigate = useNavigate();
  const [confirmationModalOpen, setConfirmationModalOpen] = useState<boolean>(false);
  const currentStage: string | undefined = challenge?.stage;
  const deadline = challenge?.deadline;
  const { t } = useTranslation();

  const deadlineInPast = useMemo(() => deadline && moment(deadline) < moment(), [deadline]);

  const handleUpdateStage = useCallback(() => {
    const newStage = !currentStage || currentStage === "closed" ? "published" : "closed";
    if (newStage === "published") {
      setConfirmationModalOpen(true);
    } else {
      updateStage("closed");
    }
  }, [currentStage, updateStage]);
  const noAudience = useMemo(
    () => !challenge?.audienceSize || challenge?.audienceSize === 0,
    [challenge?.audienceSize],
  );
  const noIdeaFormat = useMemo(
    () => !challenge?.ideaTemplateData?.body || challenge?.ideaTemplateData.body?.length === 0,
    [challenge?.ideaTemplateData?.body],
  );
  return (
    <Fragment>
      <Modal
        mountNode={document.getElementById("semantic-modal-mount-node")}
        open={confirmationModalOpen}
        onClose={() => setConfirmationModalOpen(false)}
      >
        <Modal.Header>Are you sure you want to open this {t("generic.challenge")}?</Modal.Header>
        <Modal.Content>
          {challenge?.audienceSize && challenge?.audienceSize > 0 ? (
            <Header as="h5">
              There are {util.pluralise(challenge.audienceSize, "user", "users")} in the audience of this{" "}
              {t("generic.challenge")}
            </Header>
          ) : null}
          <List bulleted>
            <List.Item>
              Notifications and/or emails will be sent to users with interests that match this {t("generic.challenge")}.
            </List.Item>
            <List.Item>
              Anyone in the {t("generic.challenge")} audience will be able to make {t("generic.ideas")} for this{" "}
              {t("generic.challenge")}.
            </List.Item>
            <List.Item>
              All information in the {t("generic.challenge")} overview will be visible to people viewing the{" "}
              {t("generic.challenge")} - Please verify no sensitive information will be revealed.
            </List.Item>
          </List>
          {(noIdeaFormat || noAudience) && (
            <Message warning>
              <Message.Header style={{ marginBottom: 5 }}>Missing {t("generic.challenge")} information</Message.Header>
              <List bulleted>
                {noAudience && (
                  <List.Item>There is currently no audience set up for this {t("generic.challenge")}.</List.Item>
                )}
                {noIdeaFormat && (
                  <List.Item>
                    There is currently no {t("generic.idea")} format selected for this {t("generic.challenge")}.
                  </List.Item>
                )}
              </List>
              <p>
                Please revisit the {t("generic.challenge")} settings to finish setting up your {t("generic.challenge")}.
              </p>
            </Message>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button content="Cancel" onClick={() => setConfirmationModalOpen(false)} />
          <Button
            content={`View ${t("generic.challenge")} settings`}
            icon="cog"
            onClick={() => {
              setConfirmationModalOpen(false);
              navigate(`/challenges/${challenge?._id}/settings`);
            }}
          />
          {util.hasPermission(user, "org.viewDashboard", challenge?.organisation) ? (
            <Dropdown trigger={<Button primary content={`Open ${t("generic.challenge")}`} />} icon={null}>
              <Dropdown.Menu>
                <Dropdown.Header>Open {t("generic.challenge")}</Dropdown.Header>
                <Dropdown.Item
                  onClick={() => {
                    setConfirmationModalOpen(false);
                    updateStage("published", false);
                  }}
                >
                  ... <b>with</b> sending notifications/emails
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    setConfirmationModalOpen(false);
                    updateStage("published", true);
                  }}
                >
                  ... <b>without</b> sending notifications/emails
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <Button
              primary
              content={`Open ${t("generic.challenge")}`}
              onClick={() => {
                setConfirmationModalOpen(false);
                updateStage("published");
              }}
            />
          )}
        </Modal.Actions>
      </Modal>
      <DisabledExplanationPopup
        content={`You cannot open this ${t("generic.challenge")} as it currently has a deadline in the past, and therefore will close as soon as it is opened. Please update the deadline, or remove it, in the ${t("generic.challenge")} settings`}
        disabled={challenge?.stage !== "published" && deadlineInPast}
        on="hover"
        hoverable
      >
        <div>{cloneElement(trigger, { onClick: () => handleUpdateStage() })}</div>
      </DisabledExplanationPopup>
    </Fragment>
  );
};

const mapStateToProps = (
  state: { user: Other.IUserMe; challenges: { challenges: Other.IChallenge[] } },
  props: IChallengeStageChanger,
) => {
  const challenge = state.challenges.challenges.find((c) => c._id === props.challengeId);
  return { user: state.user, challenge };
};
const mapDispatchToProps = (dispatch) => ({
  update: (id, challenge) => dispatch(actions.challenges.update(id, challenge)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChallengeStageChanger);
