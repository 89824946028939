import React, { useState, useCallback, useEffect, useContext, useMemo, useRef } from "react";
import { Button, Label, Icon, Message, Container, Popup } from "semantic-ui-react";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import toast from "react-hot-toast";
import api from "api";
import util from "utils/utils";
import constants from "utils/constants";
import styled, { ThemeContext } from "styled-components";
import actions from "actions";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { SearchParams } from "simplydo/core";

import SlidingModal from "components/lib/SlidingModal";
import TagModalChooser from "components/lib/Choosers/Tags/TagModalChooser";
import EmojiChooser from "components/lib/Emoji/EmojiChooser";
import UserChooser from "components/lib/Choosers/UserChooser";
import { UserChip, IdeaBusinessProfileChip } from "components/lib/Chips";
import { IdeaFollowButton } from "components/lib/FollowButton";
import { PlaceholderItem, PlaceholderStack } from "components/lib/UI";
import IdeaPDFExport from "components/lib/PDFExport/IdeaPDFExport";
import { IdeaLikeHandler } from "components/lib/Ideas";
import Emoji from "components/lib/Emoji/Emoji";
import { HoverActions, HoverAction, HoverActionsContentBox, HoverActionButton } from "components/lib/HoverActions";
import PinIdeaHelper from "../PinIdeaHelper";
import IdeaBody from "components/ideas/Body";
import { useAssessmentEditor } from "components/ideas/hooks";

import ProjectBoardActions, { UNSAVED_ASSIGNEE_TEXT } from "./ProjectBoardActions";
import RequestJoinModal from "../RequestJoinModal";

const ModalContainer = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: row;
  flex: 1;
`;

const PreviewContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: #f2f2f3;
`;

const PreviewHeader = styled.div`
  width: 100%;
  position: relative;

  .overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    z-index: 11;
  }
  .overlay-body {
    position: relative;
    z-index: 12;
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
  top: 0;
  left: 0;
  ${({ $coverImage }) =>
    $coverImage
      ? `
    background-image: url(${$coverImage});
    background-size:cover;
    background-position: center center;
  `
      : `
    background-color: #e9ebee;
  `}
  border-bottom: 1px solid lightgray;
  z-index: 999;
  min-height: 175px;
  overflow: hidden;
  text-overflow: ellipsis;
  .ui.button {
    border: 0.5px solid gray;
  }
  h2 {
    color: ${({ $isDefaultCoverImage }) => ($isDefaultCoverImage ? "black" : "white")};
    font-size: 1.65em;
    font-weight: 850;
    text-align: center;
  }
  span {
    color: ${({ $isDefaultCoverImage }) => ($isDefaultCoverImage ? "black" : "white")};
    font-weight: 500;
  }
  .follow-chip {
    color: black;
  }
`;

const IdeaContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 13;
  background-color: #f2f2f3;
`;

const BodyContainer = styled.div`
  width: 100%;
  padding: 10px;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${({ $justifyContent }) => $justifyContent || "flex-end"};
  align-items: center;
  ${({ $wrap }) =>
    $wrap &&
    `
    flex-wrap: wrap;
    margin-top: -7.5px;
    > * {
      margin-top: 7.5px;
    }
  `}
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const EmojiChooserContainer = styled.div`
  width: 100%;
  .emoji-mart {
    border: none;
    width: 100% !important;
  }
`;

const IdeaPreview = (props) => {
  const {
    isOpen,

    idea,
    loading,
    challenge: propChallenge,

    closeModal: propCloseModal = () => {},
    setIsClosing = () => {},

    onAssessmentEdited,
    moveIdea,
    canGoNextIdea,
    canGoPrevIdea,
    assessmentEnabled,
    onUpdateIdea,
    isProjectBoard,
    forBoardId,
    getBoard,
    removeIdeaFromBoard,
    openToComments,
    onIdeaActivity,
    updateIdeaPosition,
    lanes,
    removeIdeaFromMerge,
    removingIdeaFromMerge,
    currentIdeaPosition,
    totalIdeas,
  } = props;
  const user = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const previewContainerRef = useRef(null);
  const [modalIdeaWidth, setModalIdeaWidth] = useState(0);

  const sectionRefs = useRef({});

  const setRef = useCallback((ref, itemId) => {
    sectionRefs.current[itemId] = ref;
  }, []);

  const onFollow = useCallback(
    (context, id) => {
      dispatch(actions.user.onFollow(context, id));
    },
    [dispatch],
  );

  const { t } = useTranslation();

  const theme = useContext(ThemeContext);
  const [commentsOpen, setCommentsOpen] = useState(false);
  const [previewInitialised, setPreviewInitialised] = useState(false);
  const [joinModalOpen, setJoinModalOpen] = useState(false);
  const [emojiChooserOpen, setEmojiChooserOpen] = useState(false);
  const challengeId = idea?.challenge;
  const board = useMemo(() => getBoard(idea, challengeId), [getBoard, idea, challengeId]);
  const userId = user?._id;
  const ideaId = idea?._id;
  const boardId = forBoardId || challengeId;

  const { assessmentState, updateAssessment, submitAssessment } = useAssessmentEditor(idea, onAssessmentEdited);
  const { assessment, isLoading: assessmentLoading, savedAt: assessmentSavedAt } = assessmentState;

  // only show pm board management actions if the user can manage challenge (collaborator/owner/admins) or is assigned to the idea
  const canViewEntireChallenge = util.canViewEntireChallenge(user, propChallenge ?? idea?.ownerChallenge);
  const canProjectManage = util.canManageChallenge(user, propChallenge ?? idea?.ownerChallenge);
  const canCreateProjectNote = util.canCreateProjectNote(user, propChallenge ?? idea?.ownerChallenge);
  const canViewBoardActions = canViewEntireChallenge || board?.assignees?.indexOf(user._id) > -1;

  useEffect(() => {
    if (openToComments) setCommentsOpen(true);
  }, [openToComments]);

  const pinIdea = useCallback(() => {
    api.ideas.updateStatus(
      ideaId,
      { isPinned: !idea?.isPinned },
      (ideaStatus) => {
        onUpdateIdea({ isPinned: ideaStatus.isPinned });
      },
      (err) => toast.error(err.message),
    );
  }, [ideaId, idea, onUpdateIdea]);

  const updateTagListWithTag = useCallback((taggedIdea, tag) => {
    const currentChallenge = taggedIdea?.ownerChallenge;
    const tagList = [...currentChallenge.tagList];
    const index = tagList.findIndex((t) => t._id === tag._id);
    tagList[index] = tag;
    return { ...currentChallenge, tagList };
  }, []);

  const addTag = useCallback(
    (tag) => {
      api.tags.tagSingle(
        "ideas",
        tag._id,
        ideaId,
        (updatedTag) => {
          const fullTags = [...(idea?.fullTags ?? [])];
          fullTags.push(updatedTag);
          const updatedChallenge = updateTagListWithTag(idea, updatedTag);
          onUpdateIdea({ fullTags, tags: fullTags.map((t) => t._id), ownerChallenge: updatedChallenge });
        },
        (err) => toast.error(err.message),
      );
    },
    [ideaId, idea, updateTagListWithTag, onUpdateIdea],
  );

  const removeTag = useCallback(
    (tag) => {
      api.tags.untagSingle("ideas", tag._id, ideaId, (updatedTag) => {
        const fullTags = [...(idea?.fullTags ?? [])];
        const cIndex = fullTags.findIndex((t) => t._id === updatedTag._id);
        fullTags.splice(cIndex, 1);
        const updatedChallenge = updateTagListWithTag(idea, updatedTag);
        onUpdateIdea({ fullTags, tags: fullTags.map((t) => t._id), ownerChallenge: updatedChallenge });
      });
    },
    [ideaId, idea, updateTagListWithTag, onUpdateIdea],
  );

  const voteForIdea = useCallback(() => {
    api.ideas.vote(
      ideaId,
      !idea?.votedFor,
      ({ addedVotesFor, removedVotesFor }) => {
        const newIdea = { ...idea };
        if (!newIdea.voteCount) newIdea.voteCount = 0;
        if (addedVotesFor.indexOf(idea._id) > -1) {
          onFollow("challenge", idea.challenge);
          newIdea.votedFor = true;
          newIdea.voteCount += 1;
        }
        if (removedVotesFor.indexOf(idea._id) > -1) {
          newIdea.votedFor = false;
          newIdea.voteCount -= 1;
        }
        onUpdateIdea({ voteCount: newIdea.voteCount, votedFor: newIdea.votedFor });
      },
      (err) => {
        toast.error(err.message);
      },
    );
  }, [ideaId, idea, onUpdateIdea, onFollow]);

  const labelIdea = useCallback(
    (label, isApplied) => {
      const stamps = idea?.stamps ?? [];
      let existingIndex = stamps.indexOf(label);
      if (label in constants.legacyStampMapper) {
        existingIndex = stamps.indexOf(constants.legacyStampMapper[label]);
        if (existingIndex < 0) {
          existingIndex = stamps.indexOf(label);
        }
      }
      if (isApplied && existingIndex === -1) stamps.push(label);
      if (!isApplied && existingIndex > -1) stamps.splice(existingIndex, 1);
      api.ideas.updateStatus(ideaId, { stamps }, () => {
        onUpdateIdea({ stamps });
      });
    },
    [ideaId, idea, onUpdateIdea],
  );

  const addAssessors = useCallback(
    (users) => {
      const existingUsers = users.filter((assessor) => !assessor.isEmailInvitee);
      if (existingUsers.length === 0) {
        return;
      }
      api.challenges.assignAssessors(
        boardId,
        [ideaId],
        existingUsers.map((u) => u._id),
        ({ ideas }) => {
          const updatedIdea = ideas[0];
          onUpdateIdea({
            assessment: updatedIdea.assessment,
            assessors: updatedIdea.assessors,
            isAssessor: updatedIdea.assessors?.length && updatedIdea.assessors.indexOf(userId) > -1,
          });
          toast.success("Assessors updated");
        },
        (err) => toast.error(err.message),
      );
    },
    [ideaId, userId, boardId, onUpdateIdea],
  );

  const isDefaultCoverImage = idea?.coverImageUrl === "https://cdn.simplydo.co.uk/images/idea.png";
  const [potentialAssignees, setPotentialAssignees] = useState([]);

  const closeModal = useCallback(() => {
    setIsClosing(true);
    propCloseModal();
  }, [propCloseModal, setIsClosing]);

  // Catch users closing the modal while having unsaved assignment changes
  const onRequestCloseModal = useCallback(() => {
    setIsClosing(true);
    const onConfirm = () => {
      closeModal();
    };
    if (potentialAssignees.length > 0) {
      util
        .confirm(UNSAVED_ASSIGNEE_TEXT[0], UNSAVED_ASSIGNEE_TEXT[1])
        .then(() => {
          onConfirm();
        })
        .catch(() => {});
    } else {
      onConfirm();
    }
  }, [setIsClosing, potentialAssignees.length, closeModal]);

  const BoardActions = useMemo(
    () => (
      <ProjectBoardActions
        idea={idea}
        forBoardId={boardId}
        getBoard={getBoard}
        canManage={canProjectManage}
        onUpdateIdea={onUpdateIdea}
        commentsOpen={commentsOpen}
        setCommentsOpen={setCommentsOpen}
        removeIdeaFromBoard={removeIdeaFromBoard}
        assessmentEnabled={assessmentEnabled}
        isProjectBoard={isProjectBoard}
        lanes={lanes}
        updateIdeaPosition={updateIdeaPosition}
        hideComments
        onIdeaActivity={onIdeaActivity}
        challenge={propChallenge}
        potentialAssignees={potentialAssignees}
        setPotentialAssignees={setPotentialAssignees}
        canViewEntireChallenge={canViewEntireChallenge}
        canCreateProjectNote={canCreateProjectNote}
      />
    ),
    [
      idea,
      boardId,
      canCreateProjectNote,
      getBoard,
      canProjectManage,
      onUpdateIdea,
      commentsOpen,
      removeIdeaFromBoard,
      assessmentEnabled,
      isProjectBoard,
      lanes,
      updateIdeaPosition,
      onIdeaActivity,
      propChallenge,
      potentialAssignees,
      canViewEntireChallenge,
    ],
  );

  const updateQueryParams = useCallback(() => {
    const currQueryParams = new SearchParams(window.location.search);
    const currQueryParamsObject = Object.fromEntries(currQueryParams);
    const shouldIncludeIdeaId = !!ideaId;
    const { idea: _, ...otherQueryParams } = currQueryParamsObject;
    const newQueryParams = shouldIncludeIdeaId ? { ...otherQueryParams, idea: ideaId } : otherQueryParams;

    const queryParams = new SearchParams(newQueryParams).toString();
    navigate({ search: `?${queryParams}` }, { replace: true });
  }, [ideaId, navigate]);

  const onOpenPreview = useCallback(() => {
    updateQueryParams();
    setModalIdeaWidth(previewContainerRef.current?.clientWidth ?? 0);
  }, [previewContainerRef, updateQueryParams]);

  // To prevent the preview immediately resetting the query params to nothing, we only set the query parameters once the preview has been open ONCE
  // It's then permanently allowed to be edited
  useEffect(() => {
    if (idea) {
      setPreviewInitialised((prev) => prev || true);
    }
  }, [idea]);

  useEffect(() => {
    if (previewInitialised) {
      updateQueryParams();
    }
  }, [updateQueryParams, previewInitialised]);

  return (
    <SlidingModal
      isOpen={isOpen}
      onOpen={onOpenPreview}
      onClose={() => closeModal()}
      onRequestClose={onRequestCloseModal}
      onAfterClose={() => setIsClosing(false)}
      width={theme?.sizes.isComputer ? "60%" : "100%"}
      preventUncontrolledClosure
    >
      {idea ? (
        <RequestJoinModal
          idea={idea}
          open={joinModalOpen}
          onClose={() => setJoinModalOpen(false)}
          onConfirm={() => {
            onUpdateIdea({ requestedToJoin: true });
          }}
        />
      ) : null}
      <ModalContainer>
        {!theme?.sizes.isMobile && idea && !loading ? (
          <HoverActions $width={modalIdeaWidth}>
            <div className="hover-actions-inner">
              {isProjectBoard && canViewBoardActions ? BoardActions : null}

              {util.canManageChallenge(user, idea.ownerChallenge) ? (
                <HoverAction
                  icon="pin"
                  onClick={pinIdea}
                  popupContent={<PinIdeaHelper challenge={idea.ownerChallenge} />}
                  popupPosition="bottom center"
                >
                  {idea?.isPinned ? `Un-pin ${t("generic.idea")}` : `Pin ${t("generic.idea")}`}
                </HoverAction>
              ) : null}
              {idea.ownerChallenge && idea.ownerChallenge.votingVisibility === "users" && !isProjectBoard ? (
                <IdeaLikeHandler
                  idea={idea}
                  challenge={idea.ownerChallenge}
                  onLikeIdea={() => voteForIdea()}
                  trigger={
                    <HoverAction icon="thumbs up">
                      {idea?.votedFor ? `Un-like ${t("generic.idea")}` : `Like ${t("generic.idea")}`}
                    </HoverAction>
                  }
                />
              ) : null}
              {!idea?.ownerChallenge?.preventIdeaCoverImages && idea?.coverImage ? (
                <HoverAction
                  as="a"
                  href={idea.coverImageUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  download={idea?.coverImage}
                  icon="download"
                >
                  Download cover image
                </HoverAction>
              ) : null}

              {util.canManageChallenge(user, idea.ownerChallenge) && !isProjectBoard ? (
                !emojiChooserOpen ? (
                  <HoverAction icon="paint brush" onClick={() => setEmojiChooserOpen(true)}>
                    Apply a label
                  </HoverAction>
                ) : (
                  <HoverActionsContentBox>
                    <HoverActionButton onClick={() => setEmojiChooserOpen(false)}>
                      <Icon name="paint brush" style={{ height: "1.1em" }} />
                      <span>Apply a label</span>
                      <Icon name="close" style={{ height: "1.1em" }} onClick={() => setEmojiChooserOpen(false)} />
                    </HoverActionButton>
                    <div className="content-box">
                      <EmojiChooserContainer>
                        <EmojiChooser
                          onComplete={(em) => labelIdea(em, true)}
                          existing={idea?.stamps}
                          onRemoveExisting={(em) => labelIdea(em, false)}
                        />
                      </EmojiChooserContainer>
                    </div>
                  </HoverActionsContentBox>
                )
              ) : null}

              {idea.ownerChallenge &&
              (util.canEditIdea(user, idea) || util.canManageChallenge(user, idea.ownerChallenge)) ? (
                <TagModalChooser
                  trigger={<HoverAction icon="hashtag">{` Assign tags (${idea?.tags?.length || 0})`}</HoverAction>}
                  showTags
                  tags={idea.fullTags}
                  availableTags={idea.ownerChallenge.tagList}
                  addTag={addTag}
                  removeTag={removeTag}
                  hideView
                  canEdit
                />
              ) : null}

              <IdeaPDFExport
                ideas={[idea]}
                challenge={propChallenge}
                popupOffset={[0, -7]}
                trigger={
                  <div style={{ cursor: "pointer" }}>
                    <HoverAction icon="file pdf">Download PDF</HoverAction>
                  </div>
                }
              />
              {util.canManageChallenge(user, idea.ownerChallenge) && assessmentEnabled && !isProjectBoard ? (
                <UserChooser
                  trigger={<HoverAction icon="add user"> Assign assessors</HoverAction>}
                  enabledFeatures={{ search: true, invite: true }}
                  onComplete={(users) => {
                    const emailUsers = users.filter((u) => u.isEmailInvitee);
                    if (emailUsers.length) {
                      api.invitations.createBulk(
                        {
                          invitees: emailUsers.map((u) => u._id),
                          invitationType: "email",
                          forType: "ideaAssessor",
                          forId: idea.challenge,
                          forIdeas: [idea._id],
                        },
                        () => {
                          toast.success(`Invitation${emailUsers.length > 1 ? "s" : ""} sent`);
                        },
                      );
                    }
                    addAssessors(users);
                  }}
                  externalInvitesInAudience={idea?.ownerChallenge?.visibility?.organisations?.length > 0}
                  forId={challengeId}
                  audienceWarningText={
                    <span>
                      The users below are currently outside of the {t("generic.challenge")} audience. Users not in the{" "}
                      {t("generic.challenge")}
                      audience will be unable to view the {t("generic.challenge")} and their assessments. To ensure
                      assessors can access their assessments, add them to the{" "}
                      <a target="_blank" rel="noreferrer" href={`/challenges/${challengeId}/settings/audience`}>
                        {t("generic.challenge")} audience
                      </a>{" "}
                      after making this assignment.
                    </span>
                  }
                  clearOnComplete
                  forType="assessor"
                  instructions={`Choose users to assign as assessors of the selected ${t("generic.ideas")}. Assessors will be able to find the ${t("generic.ideas")} on their 'Assessments' tab within this ${t("generic.challenge")}.`}
                  confirm="Assign these assessors"
                  searchFunction={(term, cb, fail) =>
                    api.search.challengeAssessors(idea.challenge, term, ({ users }) => cb(users), fail)
                  }
                />
              ) : null}

              {moveIdea && isOpen ? (
                <>
                  {currentIdeaPosition && totalIdeas && (canGoNextIdea || canGoPrevIdea) && (
                    <HoverAction margined disabled>
                      Idea {currentIdeaPosition} of {totalIdeas}
                    </HoverAction>
                  )}

                  {canGoPrevIdea ? (
                    <HoverAction
                      icon="arrow left"
                      onClick={() => moveIdea(-1)}
                      margined={!(currentIdeaPosition && totalIdeas)}
                    >
                      Previous idea
                    </HoverAction>
                  ) : null}
                  {canGoNextIdea ? (
                    <HoverAction
                      icon="arrow right"
                      onClick={() => moveIdea(1)}
                      margined={!canGoPrevIdea && !(currentIdeaPosition && totalIdeas)}
                    >
                      Next idea
                    </HoverAction>
                  ) : null}
                </>
              ) : null}
            </div>
          </HoverActions>
        ) : null}
        <PreviewContainer ref={previewContainerRef}>
          {!loading && !idea ? (
            <Container style={{ height: "100vh" }}>
              <Message error style={{ margin: "2rem 0" }}>
                <Message.Header>Unable to load {t("generic.idea")}</Message.Header>
                <p>Sorry, we were unable to load this {t("generic.idea")}. Please try again later.</p>
              </Message>
              <Button
                content={`Close ${t("generic.idea")} preview`}
                icon="chevron left"
                onClick={() => onRequestCloseModal()}
              />
            </Container>
          ) : (
            <>
              <PreviewHeader $coverImage={util.ideaCoverImage(idea)} $isDefaultCoverImage={isDefaultCoverImage}>
                <div className="overlay" />
                <div className="overlay-body">
                  <FlexRow $justifyContent="space-between">
                    <div style={{ flex: 1 }}>
                      <Button
                        size="tiny"
                        icon="chevron left"
                        onClick={() => onRequestCloseModal()}
                        content="Close preview"
                      />
                    </div>
                    <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
                      {!loading ? (
                        <Button
                          primary
                          size="tiny"
                          content={`Open full ${t("generic.idea")}`}
                          icon="file alternate"
                          as={Link}
                          to={`/ideas/${ideaId}`}
                        />
                      ) : null}
                      {user &&
                      !util.canEditIdea(user, idea) &&
                      !idea?.ownerChallenge?.preventCollaboration &&
                      !loading ? (
                        <Button
                          secondary
                          disabled={idea.requestedToJoin}
                          size="tiny"
                          content={idea.requestedToJoin ? "Awaiting approval to join" : "Join team"}
                          icon="paper plane"
                          onClick={() => {
                            setJoinModalOpen(true);
                          }}
                        />
                      ) : null}
                    </div>
                    <ModalContainer style={{ flex: 1, justifyContent: "flex-end" }}>
                      {!loading ? (
                        <>
                          <IdeaFollowButton idea={idea} buttonProps={{ size: "tiny" }} />
                          {idea.ownerChallenge &&
                          idea.ownerChallenge?.votingVisibility === "users" &&
                          (util.canViewEntireChallenge(user, idea.ownerChallenge) ||
                            idea.ownerChallenge.voteCountVisibility === "users") ? (
                            <IdeaLikeHandler
                              idea={idea}
                              challenge={idea.ownerChallenge}
                              onLikeIdea={() => voteForIdea()}
                              trigger={
                                <Button
                                  content={
                                    theme.sizes.isMobile
                                      ? idea.voteCount
                                      : util.pluralise(idea.voteCount, "like", "likes")
                                  }
                                  size="tiny"
                                  icon="thumbs up"
                                  onClick={voteForIdea}
                                  basic={!idea.votedFor}
                                  inverted={!idea.votedFor}
                                  secondary={idea.votedFor}
                                  style={{ marginLeft: 5 }}
                                  disabled={!(idea.ownerChallenge.votingVisibility === "users")}
                                />
                              }
                            />
                          ) : null}
                        </>
                      ) : null}
                    </ModalContainer>
                  </FlexRow>
                  <FlexRow $justifyContent="center" style={{ paddingTop: 10, flex: 1 }}>
                    <FlexColumn>
                      {loading ? (
                        <PlaceholderStack gap={20} style={{ width: 400, alignItems: "center" }}>
                          <PlaceholderItem height={40} style={{ width: 400 }} />
                          <PlaceholderItem height={25} style={{ width: 200 }} />
                          <PlaceholderItem height={30} style={{ width: 200 }} />
                        </PlaceholderStack>
                      ) : (
                        <>
                          <h2 style={{ display: "block", marginBottom: 5 }}>{idea?.name?.toUpperCase()}</h2>
                          <FlexRow $wrap $justifyContent="center">
                            {idea.lockedOwnerIdeaBusinessProfile ? (
                              <IdeaBusinessProfileChip
                                ideaBusinessProfile={idea.lockedOwnerIdeaBusinessProfile}
                                inverted
                                style={{ marginRight: 15 }}
                              />
                            ) : null}
                            {idea.authors
                              ? idea.authors.map((a, idx) => (
                                  <UserChip user={a} key={idx} inverted style={{ marginRight: 15 }} />
                                ))
                              : null}
                          </FlexRow>
                          {idea.stamps?.length ? (
                            <FlexRow style={{ marginTop: 5, display: "flex" }}>
                              {idea.stamps.map((stamp, i) => (
                                <Emoji key={i} emoji={{ id: stamp }} size={22} />
                              ))}
                            </FlexRow>
                          ) : null}
                        </>
                      )}
                    </FlexColumn>
                  </FlexRow>

                  <div style={{ display: "flex", justifyContent: "right", fontSize: 12, marginTop: 5 }}>
                    {board?.laneObject?.laneCount > 0 && board?.laneObject?.name && !loading ? (
                      <Label>
                        <Icon name="compass" />
                        Project lane: {board?.laneObject?.name}
                        {board.movedAt || board.movedBy ? ` - ` : ""}
                        {board.movedAt ? `Moved ${moment(board.movedAt).fromNow()}` : ""}
                        {board.movedBy ? ` by ${board.movedBy?.profile?.fullName}` : ""}
                      </Label>
                    ) : null}

                    {loading ? (
                      <PlaceholderStack width={200}>
                        <PlaceholderItem height={20} style={{ width: 100 }} />
                      </PlaceholderStack>
                    ) : idea.isSubmitted ? (
                      <Popup
                        trigger={
                          <Label color="green" style={{ color: "#fff" }}>
                            Submitted
                          </Label>
                        }
                        content={`Submitted ${moment(idea.submittedAt).format("DD/MM/YYYY HH:mm")}`}
                      />
                    ) : (
                      <Label color="yellow">Draft</Label>
                    )}
                  </div>
                </div>
              </PreviewHeader>
              <IdeaContainer>
                <BodyContainer>
                  {theme?.sizes.isMobile && canViewBoardActions && isProjectBoard ? BoardActions : null}
                  <IdeaBody
                    idea={idea ?? {}}
                    user={user}
                    setRef={setRef}
                    sectionRefs={sectionRefs}
                    challenge={idea?.ownerChallenge}
                    loading={loading}
                    assessment={assessment}
                    assessmentLoading={assessmentLoading}
                    assessmentSavedAt={assessmentSavedAt}
                    updateAssessment={updateAssessment}
                    submitAssessment={submitAssessment}
                    isPreview
                    canEdit={false}
                    isEditing={false}
                    divRef={previewContainerRef}
                    removeIdeaFromMerge={removeIdeaFromMerge}
                    removingIdeaFromMerge={removingIdeaFromMerge}
                  />
                </BodyContainer>
              </IdeaContainer>
            </>
          )}
        </PreviewContainer>
      </ModalContainer>
    </SlidingModal>
  );
};

export const HandledIdeaPreview = ({
  ideaId,
  getBoard = (searchIdea) => searchIdea?.projectManagement?.boards?.find((b) => b.forId === searchIdea.challenge),
  onUpdateIdea: propUpdateIdea = (_ideaId, _data) => {},
  removeIdeaFromMerge: propRemoveIdeaFromMerge,
  ...rest
}) => {
  const [idea, setIdea] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    if (ideaId) {
      setLoading(true);
      api.ideas.get(
        ideaId,
        (data) => {
          setIdea(data);
          setLoading(false);
        },
        () => {
          setLoading(false);
        },
      );
    } else if (!isClosing) {
      setIdea(null);
    }
  }, [ideaId, isClosing]);

  const onUpdateIdea = useCallback(
    (data) => {
      const updatedIdea = { ...idea, ...data };
      propUpdateIdea(idea._id, data);
      setIdea(updatedIdea);
    },
    [idea, setIdea, propUpdateIdea],
  );

  const removeIdeaFromMerge = useCallback(
    (mergeIdeaId, childIdeaId) => {
      propRemoveIdeaFromMerge(mergeIdeaId, childIdeaId, () => {
        if (mergeIdeaId === ideaId) {
          setIdea((prevIdea) => ({
            ...prevIdea,
            children: prevIdea.children.filter((c) => c !== childIdeaId),
            ownerChildren: prevIdea.ownerChildren.filter((c) => c._id !== childIdeaId),
          }));
        }
      });
    },
    [ideaId, propRemoveIdeaFromMerge],
  );

  return (
    <IdeaPreview
      isOpen={!!ideaId}
      idea={idea}
      getBoard={getBoard}
      onUpdateIdea={onUpdateIdea}
      loading={loading}
      removeIdeaFromMerge={removeIdeaFromMerge}
      isClosing={isClosing}
      setIsClosing={setIsClosing}
      {...rest}
    />
  );
};

export default IdeaPreview;
