const userActions = {
  RECEIVE_USER: "RECEIVE_USER",
  CREATE_GROUP: "CREATE_GROUP",
  JOIN_GROUP: "JOIN_GROUP",
  LEAVE_GROUP: "LEAVE_GROUP",
  UPDATE_TERMS_AGREEMENT: "UPDATE_TERMS_AGREEMENT",
  UPDATE_PROFILE: "UPDATE_PROFILE",
  UPDATE_EMAILS: "UPDATE_EMAILS",
  UPDATE_PHONE_NUMBERS: "UPDATE_PHONE_NUMBERS",
  TOGGLE_EVENT_SUBSCRIPTION: "TOGGLE_EVENT_SUBSCRIPTION",
  SWITCH_ORGANISATION: "SWITCH_ORGANISATION",
  UPDATE_ORGANISATION: "UPDATE_ORGANISATION",
  ADD_ENGAGEMENT: "ADD_ENGAGEMENT",
  LOGOUT: "LOGOUT",
  UPDATE_PERMISSIONS: "UPDATE_PERMISSIONS",
  UPDATE_PUBLIC_IDEAS: "UPDATE_PUBLIC_IDEAS",
  UPDATE_ENABLED_SERVICES: "UPDATE_ENABLED_SERVICES",
  UPDATE_EMAIL_ADDRESS: "UPDATE_EMAIL_ADDRESS",
  GHOST: "GHOST",
  UNGHOST: "UNGHOST",
  SHOW_DASHBOARD_TEXT: "SHOW_DASHBOARD_TEXT",
  HIDE_DASHBOARD_TEXT: "HIDE_DASHBOARD_TEXT",
  HIDE_SECURITY_RECOMMENDATIONS: "HIDE_SECURITY_RECOMMENDATIONS",
  ENABLE_MFA: "ENABLE_MFA",
  UPDATE_AVAILABLE_ORGANISATIONS: "UPDATE_AVAILABLE_ORGANISATIONS",
  UPDATE_FEATURE_FLAGS: "UPDATE_FEATURE_FLAGS",
  UPDATE_TABLE_PREFS: "UPDATE_TABLE_PREFS",
  UPDATE_SPREADSHEET_PREFS: "UPDATE_SPREADSHEET_PREFS",
  ON_FOLLOW: "ON_FOLLOW",
  ADD_IDEA_ID: "ADD_IDEA_ID",
  ADD_COMPANY: "ADD_COMPANY",
  UPDATE_TAGS: "UPDATE_TAGS",
  ON_ACCEPT_INVITATION: "ON_ACCEPT_INVITATION",
  TRACK_ONBOARDING_COMPLETION: "TRACK_ONBOARDING_COMPLETION",
  UPDATE_INNOVATION_INTELLIGENCE: "UPDATE_INNOVATION_INTELLIGENCE",

  receiveUser(user) {
    return { type: this.RECEIVE_USER, user };
  },
  createGroup(id, name) {
    return { type: this.CREATE_GROUP, id, name };
  },
  joinGroup(group) {
    return { type: this.JOIN_GROUP, id: group._id, name: group.name };
  },
  leaveGroup(group) {
    return { type: this.LEAVE_GROUP, id: group._id };
  },
  agreeTerms(legalAgreements) {
    return { type: this.UPDATE_TERMS_AGREEMENT, legalAgreements };
  },
  updateProfile(profile) {
    return { type: this.UPDATE_PROFILE, profile };
  },
  updatePhoneNumbers(phoneNumbers) {
    return { type: this.UPDATE_PHONE_NUMBERS, phoneNumbers };
  },
  updateEmails(emails) {
    return { type: this.UPDATE_EMAILS, emails };
  },
  toggleEventSubscription(subscriptions) {
    return { type: this.TOGGLE_EVENT_SUBSCRIPTION, subscriptions };
  },
  switchOrganisation(organisation) {
    return { type: this.SWITCH_ORGANISATION, organisation };
  },
  updateOrganisation(field, value) {
    return { type: this.UPDATE_ORGANISATION, field, value };
  },
  logout() {
    return { type: this.LOGOUT };
  },
  addEngagement(engagement) {
    return { type: this.ADD_ENGAGEMENT, engagement };
  },
  updatePermissions(scope, permissions) {
    return { type: this.UPDATE_PERMISSIONS, scope, permissions };
  },
  updatePublicIdeas() {
    return { type: this.UPDATE_PUBLIC_IDEAS };
  },
  updateEnabledServices(services) {
    return { type: this.UPDATE_ENABLED_SERVICES, services };
  },
  updateEmailAddress(email) {
    return { type: this.UPDATE_EMAIL_ADDRESS, email };
  },
  updateFeatureFlags(featureFlags) {
    return { type: this.UPDATE_FEATURE_FLAGS, featureFlags };
  },
  updateTablePreferences(key, preferences) {
    return { type: this.UPDATE_TABLE_PREFS, key, preferences };
  },
  updateSpreadsheetPreferences(key, preferences) {
    return { type: this.UPDATE_SPREADSHEET_PREFS, key, preferences };
  },
  ghost(id) {
    return { type: this.GHOST, id };
  },
  unGhost() {
    return { type: this.UNGHOST };
  },
  hideDashboardText() {
    return { type: this.HIDE_DASHBOARD_TEXT };
  },
  showDashboardText() {
    return { type: this.SHOW_DASHBOARD_TEXT };
  },
  hideSecurityRecommendations() {
    return { type: this.HIDE_SECURITY_RECOMMENDATIONS };
  },
  enableMfa() {
    return { type: this.ENABLE_MFA };
  },
  updateAvailableOrganisations(organisations) {
    return { type: this.UPDATE_AVAILABLE_ORGANISATIONS, organisations };
  },
  updateTags(tags) {
    return { type: this.UPDATE_TAGS, tags };
  },
  onFollow(context, id, manual = false) {
    return {
      type: this.ON_FOLLOW,
      context,
      id,
      manual,
    };
  },
  addIdeaId(ideaId) {
    return { type: this.ADD_IDEA_ID, ideaId };
  },
  addCompany(company) {
    return { type: this.ADD_COMPANY, company };
  },
  onAcceptInvitation() {
    return { type: this.ON_ACCEPT_INVITATION };
  },
  trackOnboardingCompletion() {
    return { type: this.TRACK_ONBOARDING_COMPLETION };
  },
  updateInnovationIntelligenceSearches(searchType, searches) {
    return { type: this.UPDATE_INNOVATION_INTELLIGENCE, searchType, searches };
  },
};

export default userActions;
