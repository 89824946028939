import React, { useState, useContext, useMemo, useRef, useEffect, useCallback } from "react";
import { Icon, Dropdown, Button, Label } from "semantic-ui-react";
import { OpenAPI } from "simplydo/interfaces";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Link, useNavigate, useLocation } from "react-router-dom";
import styled, { ThemeContext } from "styled-components";
import util from "utils/utils";
import api from "api";
import actions from "actions";
// @ts-ignore
import { languages, setInterpolationDictionary } from "src/i18n";
import websocketApi from "api/websocket";

import HighlightLink from "components/lib/Navigation/HighlightLink";
import HoverLinkDropdown, { HoverLink } from "components/lib/Navigation/HoverLinkDropdown";
import MessageTray from "components/lib/Messaging/MessageTray";
import NotificationTray from "components/lib/Notifications/NotificationTray";
import { FakeLink } from "components/lib/UI";
import OmniBar from "components/lib/OmniBar";
import ManageLinksModal from "components/admin/Links/ManageLinksModal";
import OrgSwitcherTray from "../OrgSwitcherTray";

const StyledNavBar = styled.header`
  background: rgb(255, 255, 255);
  flex: 0 0 50px;
  gap: ${({ theme }) => (theme.sizes.isMobile ? 10 : 20)}px;
  position: sticky;
  width: 100%;
  top: 0;
  border-bottom: 1px solid rgb(204, 204, 204);
  display: flex;
  align-content: stretch;
  align-items: stretch;
  justify-content: space-between;
  z-index: 1000;
`;

export const StyledNavBarSubSegment = styled.div`
  background-color: #f4f4f4;
  border-left: 1px solid #dedede;
  padding: 0 16px 0 14px;
  margin-left: ${({ theme }) => (theme.sizes.isMobile ? "10px" : "20px")};
  height: 50px;
  margin-right: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledNavBarSegment = styled.div<{ $alignRight?: boolean }>`
  display: flex;
  align-items: center;
  ${({ $alignRight }) => $alignRight && "justify-content: flex-end;"}
  &:first-of-type {
    margin-left: 10px;
  }
  &:last-of-type {
    margin-right: 10px;
  }
`;

const StyledLogo = styled(Link)`
  margin-right: 10px;
  img {
    vertical-align: middle;
    max-height: 40px;
    max-width: ${({ theme }) => (!theme.sizes.isComputer ? 100 : 150)}px;
  }
`;

const ActionContainer = styled.div`
  display: flex;
  flex: 1;
  flex-grow: 1;
  align-items: center;
  text-transform: capitalize;
`;

const StyledNavIcon = styled.div`
  display: inline-block;
  font-size: 20px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: rgb(240, 240, 240);
  transition: background-color 0.25s;
  cursor: pointer;
  margin-right: ${({ theme }) => (theme.sizes.isMobile ? "10px" : "20px")};
  position: relative;
  .icon {
    display: block;
    margin: 5px auto;
  }
  a,
  .icon {
    transition: color 0.25s;
    color: rgb(120, 136, 150);
  }
  &:hover {
    background-color: ${({ theme }) => theme.accent};
    a,
    .icon {
      color: ${({ theme }) => (theme.shouldBeWhiteOnAccent ? "white" : "#4a4544")};
    }
  }
`;

const StyledNavAvatar = styled.div<{ $user?: { profile: { avatarUrl: string } } }>`
  width: 30px;
  height: 30px;
  cursor: pointer;
  border-radius: 50%;
  overflow: hidden;
  background-color: white;
  background-size: cover;
  background-position: center center;
  margin-right: 0px;
  background-image: ${({ $user }) => `url(${util.mixinCssUrlFallback(util.avatarUrl($user), util.avatarUrl())})`};
  outline: 1px solid #e2e2e2;
  transition: box-shadow 0.25s;
  &:hover {
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  }
`;

const StyledMobileSearchBar = styled.div`
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: #fff;
  position: fixed;
  width: 100%;
  z-index: 100;
  top: 50px;
`;

function NavBar({
  user,
  urlOrganisation,
  isAuthenticated,
  onSwitchOrganisation,
  t,
  i18n,
  newMessageCount,
  updateProfile,
}) {
  const theme = useContext(ThemeContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [omniSearchOpen, setOmniSearchOpen] = useState(false);
  const [userBarOpen, setUserBarOpen] = useState(false);
  const [manageLinksModalOpen, setManageLinksModalOpen] = useState(false);
  const profileUpdateRef = useRef(null);
  const selectedLanguage = languages.filter((l) => l.key === (i18n.language || "en"))[0];
  const organisation = user?.organisation || urlOrganisation;

  const [pinnedPages, setPinnedPages] = useState<OpenAPI.GET<"/pages/pinned">["response"]["pages"]>([]);
  const orgId = user?.organisation?._id;

  const getPinnedPages = useCallback(() => {
    api.pages.getPinned(
      "organisation",
      orgId,
      ({ pages: newPages }) => {
        setPinnedPages(newPages);
      },
      () => {},
    );
  }, [orgId]);

  useEffect(() => {
    getPinnedPages();
  }, [getPinnedPages]);

  const unGhost = () => {
    api.users.unGhost(
      () => {},
      () => {},
    );
  };

  const logout = () => {
    api.auth.logout(
      () => {
        navigate("/");
      },
      () => {},
    );
  };

  const switchOrganisation = (orgId) => {
    api.users.switchOrganisation(
      user._id,
      orgId,
      (data) => {
        onSwitchOrganisation(data.organisation);
      },
      () => {},
    );
  };

  // Subscribe to websocket updates to check if Microsoft SSO has applied a profile pic automatically
  // It's highly possible the websocket subscription "misses" a Microsoft profile picture update; Simply because the event that sets the profile picture could conceivably finish in the nether zone between the point where /users/me() has been returned to the client, and before we are subscribed to websockets.
  // There's nothing we can do about this - Therefore, to cover this gap, if we know the user has logged in with SSO, we make a single request to get their profile and load in the profile image if it's not already there.
  const userId = user?._id;
  const isSsoUser = !!user?.ssoUser;
  const userHasAvatarImage = !!user?.profile?.avatar;
  useEffect(() => {
    if (userId && isSsoUser && !userHasAvatarImage) {
      profileUpdateRef.current = websocketApi.subscribe(
        `userProfileUpdated-${userId}`,
        (data) => {
          // @ts-ignore
          updateProfile(data.profile);
        },
        { receiveErrorUpdates: false, receiveUserUpdates: false },
      );
      api.users.get(
        userId,
        (data) => {
          if (data?.profile?.avatar && data?.profile?.avatarUrl) {
            updateProfile({
              avatar: data.profile.avatar,
              avatarUrl: util.avatarUrl(data),
              avatarWasSetByExternalSource: !!data.profile.avatarWasSetByExternalSource,
            });
          }
        },
        () => {},
      );
    }
    return () => {
      if (profileUpdateRef.current) {
        profileUpdateRef.current.unsubscribe();
      }
    };
  }, [isSsoUser, updateProfile, userId, userHasAvatarImage]);

  const canAccessInnovationIntelligence = useMemo(
    () =>
      util.organisationFeaturesEnabled(user, ["innovationIntelligence"]) &&
      util.hasPermission(user, "org.innovationIntelligence", user.organisation._id),
    [user],
  );

  const shouldShowMoreMenu = useMemo(
    () => !!organisation?.links?.length || canAccessInnovationIntelligence || !!pinnedPages.length,
    [organisation?.links?.length, canAccessInnovationIntelligence, pinnedPages.length],
  );

  return (
    <>
      <ManageLinksModal isOpen={manageLinksModalOpen} onClose={() => setManageLinksModalOpen(false)} />

      <StyledNavBar>
        <StyledNavBarSegment style={{ flex: 1, flexGrow: 1 }}>
          <StyledLogo to="/">
            <img alt="" src={organisation?.darkLogoUrl} />
          </StyledLogo>
          <ActionContainer>
            {!theme.sizes.isComputer ? (
              user && (
                <Dropdown
                  icon={null}
                  trigger={
                    <StyledNavIcon theme={theme}>
                      <Icon name="bars" />
                    </StyledNavIcon>
                  }
                  style={{ zIndex: 999 }}
                >
                  <Dropdown.Menu>
                    {user && (
                      <Dropdown.Item
                        as={Link}
                        to="/challenges"
                        selected={util.activePath(/^\/challenges([A-z0-9/])*\w*/g)}
                        content={t("common:capitalise", { key: "generic.challenges" })}
                      />
                    )}
                    {user && (
                      <Dropdown.Item
                        as={Link}
                        to="/ideas"
                        selected={
                          !util.activePath(/^\/challenges([A-z0-9/])*\w*/g) &&
                          util.activePath(/^\/ideas([A-z0-9/])*\w*/g)
                        }
                        content={t("common:capitalise", { key: "generic.ideas" })}
                      />
                    )}
                    {user &&
                      (user.groupsAvailable || util.hasPermission(user, "org.createGroups", user.organisation._id)) && (
                        <Dropdown.Item
                          as={Link}
                          to="/groups"
                          selected={util.activePath(/^\/groups([A-z0-9/])*\w*/g)}
                          content={t("generic.groups")}
                        />
                      )}
                    {util.hasPermission(user, "org.viewDashboard", user && user.organisation._id) && (
                      <Dropdown.Item
                        as={Link}
                        to="/admin"
                        selected={util.activePath(/^\/admin([A-z0-9/])*\w*/g)}
                        content={t("generic.admin")}
                      />
                    )}
                    {util.hasPermission(user, "org.viewDashboard", user && user.organisation._id) && (
                      <Dropdown.Item
                        as={Link}
                        to="/organisations"
                        selected={util.activePath(/^\/organisations([A-z0-9/])*\w*/g)}
                        content="Org Search"
                      />
                    )}
                    {organisation?.links?.length > 0 && (
                      <>
                        <Dropdown.Header>{t("generic.links")}</Dropdown.Header>
                        {organisation.links.map((link) => (
                          <Dropdown.Item
                            as="a"
                            href={link.url}
                            key={link.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            content={link.label}
                          />
                        ))}
                      </>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              )
            ) : (
              <React.Fragment>
                {user && (
                  <HighlightLink as={Link} to="/" content={t("generic.home")} selected={util.activePath(/^\/$/g)} />
                )}
                {user && (
                  <HighlightLink
                    as={Link}
                    to="/challenges"
                    selected={util.activePath(/^\/challenges([A-z0-9/])*\w*/g)}
                    content={t("generic.challenges")}
                  />
                )}
                {user && (
                  <HighlightLink
                    as={Link}
                    to="/ideas"
                    selected={
                      !util.activePath(/^\/challenges([A-z0-9/])*\w*/g) && util.activePath(/^\/ideas([A-z0-9/])*\w*/g)
                    }
                    content={t("generic.ideas")}
                  />
                )}
                {user &&
                  (user.groupsAvailable || util.hasPermission(user, "org.createGroups", user.organisation._id)) && (
                    <HoverLinkDropdown
                      type="groups"
                      title="Your groups"
                      trigger={
                        <HighlightLink
                          as={Link}
                          to="/groups"
                          selected={util.activePath(/^\/groups([A-z0-9/])*\w*/g)}
                          content={t("generic.groups")}
                        />
                      }
                    />
                  )}
                {util.hasPermission(user, "org.viewDashboard", user && user.organisation._id) ? (
                  <HoverLinkDropdown
                    trigger={
                      <HighlightLink
                        as={Link}
                        to="/admin"
                        selected={util.activePath(/^\/admin([A-z0-9/])*\w*/g)}
                        content={t("generic.admin")}
                      />
                    }
                    title="Quick Access"
                    content={
                      <HoverLink onClick={() => setManageLinksModalOpen(true)}>
                        <span>
                          <Icon name="cog" /> Manage short links
                        </span>
                      </HoverLink>
                    }
                  />
                ) : null}
                {user && shouldShowMoreMenu ? (
                  <HoverLinkDropdown
                    title="Quick access"
                    trigger={
                      <FakeLink style={{ display: "block", margin: "5px 17px" }} withBorder>
                        {t("generic.more")}
                      </FakeLink>
                    }
                    content={
                      <>
                        {canAccessInnovationIntelligence ? (
                          <HoverLink as={Link} to="/innovationintelligence">
                            Innovation Intelligence
                          </HoverLink>
                        ) : null}
                        {organisation.links && organisation.links.length > 0 ? (
                          <>
                            <span>{t("generic.links")}</span>
                            {organisation.links.map((link) => (
                              <HoverLink
                                as="a"
                                href={link.url}
                                key={link.url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {link.label}
                              </HoverLink>
                            ))}
                          </>
                        ) : null}
                        {pinnedPages && pinnedPages.length > 0 ? (
                          <>
                            <span>Pinned pages</span>
                            {pinnedPages.map((page) => (
                              <HoverLink key={page._id} as="a" target="_blank" rel="noreferrer" href={page.link}>
                                {page.title}
                              </HoverLink>
                            ))}
                          </>
                        ) : null}
                      </>
                    }
                  />
                ) : null}
              </React.Fragment>
            )}
          </ActionContainer>
        </StyledNavBarSegment>

        {user && theme.sizes.isComputer && (
          <StyledNavBarSegment style={{ flex: 1, flexGrow: 2, maxWidth: 600 }}>
            {/* @ts-ignore */}
            <OmniBar onClose={() => setOmniSearchOpen(false)} />
          </StyledNavBarSegment>
        )}

        <StyledNavBarSegment $alignRight style={{ flex: 1, flexGrow: 1 }}>
          {user && !theme.sizes.isComputer && (
            <StyledNavIcon onClick={() => setOmniSearchOpen((prevOpen) => !prevOpen)}>
              <Icon name="search" />
            </StyledNavIcon>
          )}
          {user ? (
            <NotificationTray
              // @ts-ignore
              style={{ marginRight: theme.sizes.isMobile ? 10 : 20 }}
            />
          ) : null}
          {user && !user.ghostingAs && (
            <>
              {theme.sizes.isMobile ? (
                <StyledNavIcon>
                  <Link to={"/messagetray"}>
                    <Icon name="envelope" />
                    {newMessageCount > 0 && (
                      <Label circular size="small" color="red" floating style={{ top: "0.5em", left: "auto" }}>
                        {newMessageCount}
                      </Label>
                    )}
                  </Link>
                </StyledNavIcon>
              ) : (
                <MessageTray
                  // @ts-ignore
                  style={{ marginRight: 20 }}
                />
              )}
            </>
          )}
          {/* @ts-ignore */}
          {user && theme.sizes.isComputer && (
            <StyledNavIcon onClick={() => window.$chatwoot.toggle()}>
              <Icon name="question circle" />
            </StyledNavIcon>
          )}
          {user ? (
            <Dropdown
              trigger={<StyledNavAvatar onClick={() => setUserBarOpen((prev) => !prev)} $user={user} />}
              direction="left"
              pointing="top right"
              icon={null}
              open={userBarOpen}
              onClose={() => setUserBarOpen(false)}
            >
              <Dropdown.Menu style={{ margin: "10px -4px 0 0" }}>
                <Dropdown.Header>
                  <Icon name="user circle" /> {user.profile.fullName}
                </Dropdown.Header>
                <Link to={`/users/${user._id}`} className="item">
                  <i className="user icon"></i> {t("generic.profile")}
                </Link>
                <Link to="/invitations" className="item">
                  <i className="paper plane icon"></i> {t("generic.invitations")}
                </Link>
                <Link to="/preferences" className="item">
                  <i className="cogs icon"></i> {t("generic.account")}
                </Link>
                <Link to="/achievements" className="item">
                  <i className="trophy icon"></i> {t("generic.achievements")}
                </Link>
                {user?.availableOrganisations?.length > 1 && (
                  <>
                    <Dropdown.Divider />
                    <Dropdown.Header>Switch account</Dropdown.Header>
                    {user.availableOrganisations.map((o) => (
                      <Dropdown.Item key={o._id} onClick={() => switchOrganisation(o._id)}>
                        <span style={{ width: 50, marginRight: 5 }}>
                          <img
                            alt={`${o.name} logo`}
                            src={o.darkLogoUrl}
                            style={{ maxWidth: 50, maxHeight: 35, verticalAlign: "middle" }}
                          />
                        </span>
                        {o.name}
                      </Dropdown.Item>
                    ))}
                  </>
                )}
                {(util.hasPermission(user, "super.viewDashboard") ||
                  util.hasPermission(user, "org.managePermissions", user.organisation)) && <Dropdown.Divider />}
                {util.hasPermission(user, "super.viewDashboard") && (
                  <Link to="/superadmin" className="item">
                    <i className="rocket icon"></i> Superadmin
                  </Link>
                )}
                <Dropdown.Divider />
                {user?.ghostingAs ? (
                  <Dropdown.Item onClick={unGhost}>
                    <Icon name="power off" /> Unghost user
                  </Dropdown.Item>
                ) : (
                  <Dropdown.Item onClick={logout}>
                    <Icon name="power off" /> {t("generic.logout")}
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          ) : null}

          {user ? <OrgSwitcherTray /> : null}
          {!user && isAuthenticated && <Button color="teal" content="Logout" onClick={() => api.auth.localLogout()} />}
          {!user && !isAuthenticated && !location.pathname.startsWith("/challenges/") ? ( // Don't show language chooser on unauthenticated challenge page
            <Dropdown
              direction="left"
              pointing="top right"
              trigger={<span>{selectedLanguage?.name ?? "Language"}</span>}
            >
              <Dropdown.Menu>
                {languages.map((l) => (
                  <Dropdown.Item
                    key={l.key}
                    onClick={() => {
                      i18n.changeLanguage(l.key);
                      setInterpolationDictionary(organisation?.terminology);
                    }}
                  >
                    {l.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          ) : null}
        </StyledNavBarSegment>
      </StyledNavBar>

      {!theme.sizes.isComputer && omniSearchOpen && (
        <StyledMobileSearchBar>
          {/* @ts-ignore */}
          <OmniBar onClose={() => setOmniSearchOpen(false)} />
        </StyledMobileSearchBar>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.user,
  urlOrganisation: state.organisations.urlOrganisation,
  newMessageCount: state.messages.newMessageCount,
});

const mapDispatchToProps = (dispatch) => ({
  onSwitchOrganisation: (org) => dispatch(actions.user.switchOrganisation(org)),
  updateProfile: (profile) => dispatch(actions.user.updateProfile(profile)),
});

// @ts-ignore
const NavBarContainer = connect(mapStateToProps, mapDispatchToProps)(NavBar);

export default withTranslation()(NavBarContainer);
