import React, { useState, useCallback, useEffect, useRef, useContext, useMemo } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Grid, List, Popup, Input, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import styled, { ThemeContext } from "styled-components";
import actions from "actions";
import api from "api";
import util from "utils/utils";
import useThrottle from "utils/useThrottle";
import { getPath } from "utils/locations";
import { useContainerDimensions } from "utils/useContainerDimensions";

import UserSearchItem from "./UserSearchItem";
import { StyledSearchItem } from "./SearchItem";

const StyledSearchBar = styled.div`
  margin-right: ${({ theme }) => (theme.sizes.isMobile ? 0 : 20)}px;
  width: 100%;
  .ui.input {
    border-radius: ${({ theme }) => (theme.sizes.isMobile ? "0px" : "4px")}!important;
    background-color: #e9ebee;
    width: 100%;
    input {
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-radius: ${({ theme }) => (theme.sizes.isMobile ? 0 : 4)}px;
      background-color: #e9ebee;
    }
  }
`;

const NoSearchContainer = styled.div`
  width: 170px;
  width: ${({ theme, $width }) => (theme.sizes.isMobile ? "calc(100vw - 30px)" : `calc(${$width}px - 30px)`)};
  min-width: ${({ theme }) => (theme.sizes.isMobile ? "0" : "600px")};
  overflow-wrap: break-word;
  overflow: hidden;
`;

const StyledSearchResults = styled.div`
  max-height: 760px;
  overflow: auto;
  overflow-x: hidden;
  width: ${({ theme, $width }) => (theme.sizes.isMobile ? "calc(100vw - 30px)" : `calc(${$width}px - 30px)`)};
  min-width: ${({ theme }) => (theme.sizes.isMobile ? "0" : "600px")};
  padding: 1rem;

  h4 {
    margin-bottom: 5px;
  }
  .ui.list {
    margin-top: 0;
  }
`;

const StyledTitleRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 !important;
  ${({ $spaceTop }) => $spaceTop && "margin-top: 10px !important;"}
`;

const StyledTitle = styled.h4`
  margin: 0;
`;

const StyledSpotlightResult = styled.div`
  background-color: #e9ebee;
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 5px 10px;
  border-radius: 5px;
  ${({ $active }) => $active && "background-color: #ced3d9;"}
`;

const searchResultsAreEmpty = (searchResults) => {
  return util.isEmpty(searchResults) || Object.values(searchResults).every((val) => val.length === 0);
};

function OmniBar({ user, onClose, onSwitchOrganisation }) {
  const theme = useContext(ThemeContext);
  const searchInput = useRef();
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState({});
  const [searching, setSearching] = useState(false);
  const [hovered, setHovered] = useState({});
  const [spotlightResults, setSpotlightResults] = useState([]);
  const [recentSearches, setRecentSearches] = useState([]);
  const [cursor, setCursor] = useState(0);
  const { width } = useContainerDimensions(searchInput);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const { users = [], challenges = [], ideas = [], groups = [], organisations = [], tags = [] } = searchResults;
  const totalResults = useMemo(
    () => [...spotlightResults, ...users, ...challenges, ...ideas, ...groups, ...(organisations || []), ...tags],
    [spotlightResults, users, challenges, ideas, groups, organisations, tags],
  );

  const userStartIndex = spotlightResults.length;
  const challengeStartIndex = userStartIndex + searchResults.users?.length || 0;
  const ideaStartIndex = challengeStartIndex + searchResults.challenges?.length || 0;
  const groupStartIndex = ideaStartIndex + searchResults.ideas?.length || 0;
  const organisationStartIndex = groupStartIndex + searchResults.groups?.length || 0;
  const tagStartIndex = organisationStartIndex + searchResults.organisations?.length || 0;

  useEffect(() => {
    if (!searchTerm) {
      api.search.getRecentSearches(({ recentSearches: newRecentSearches }) => setRecentSearches(newRecentSearches));
    }
  }, [searchTerm]);

  const inputClicked = () => {
    api.journey.record("users", null, "clickedOmniBar");
    if (searchTerm || recentSearches) setOpen(true);
  };

  const addRecentSearch = (type, name) => {
    api.search.recentSearch({ type, search: name });
  };

  const switchOrganisation = useCallback(
    (e, org) => {
      api.users.switchOrganisation(
        user._id,
        org._id,
        (data) => {
          onSwitchOrganisation(data.organisation);
        },
        (err) => toast.error(err.message),
      );
    },
    [user, onSwitchOrganisation],
  );

  useEffect(() => {
    if (!open) {
      return;
    }

    function downHandler({ key }) {
      switch (key) {
        case "ArrowUp":
          setCursor((prevState) => {
            const totalResultsLength = totalResults.length - 1;
            if (prevState === 0) {
              return totalResultsLength;
            }
            return prevState - 1;
          });
          break;
        case "ArrowDown":
          setCursor((prevState) => {
            const totalResultsLength = totalResults.length - 1;
            if (prevState === totalResultsLength) {
              return 0;
            }
            if (prevState < totalResultsLength) {
              return prevState + 1;
            }
            return prevState;
          });
          break;
        case "Enter": {
          const isSpotlight = cursor < userStartIndex;
          const isUser = cursor >= userStartIndex && cursor < challengeStartIndex;
          const isChallenge = cursor >= challengeStartIndex && cursor < ideaStartIndex;
          const isIdea = cursor >= ideaStartIndex && cursor < groupStartIndex;
          const isGroup = cursor >= groupStartIndex && cursor < organisationStartIndex;
          const isOrganisation = cursor >= organisationStartIndex;
          const isTag = cursor >= tagStartIndex;
          if (spotlightResults.length) {
            if (isSpotlight) {
              navigate(hovered.link);
            } else if (isUser) {
              addRecentSearch("user", hovered.profile.fullName);
              navigate(`/users/${hovered._id}`);
            } else if (isChallenge) {
              addRecentSearch("challenge", hovered.name);
              navigate(`/challenges/${hovered._id}`);
            } else if (isIdea) {
              addRecentSearch("idea", hovered.name);
              navigate(`/ideas/${hovered._id}`);
            } else if (isGroup) {
              addRecentSearch("group", hovered.name);
              navigate(`/groups/${hovered._id}`);
            } else if (isOrganisation) {
              addRecentSearch("organisation", hovered.name);
              switchOrganisation(null, hovered);
            } else if (isTag) {
              navigate(`/tags/${hovered._id}`);
            }
          }
          break;
        }
      }
    }

    window.addEventListener("keydown", downHandler);
    return () => {
      window.removeEventListener("keydown", downHandler);
    };
  }, [
    challengeStartIndex,
    cursor,
    groupStartIndex,
    hovered,
    ideaStartIndex,
    navigate,
    open,
    organisationStartIndex,
    spotlightResults.length,
    switchOrganisation,
    tagStartIndex,
    totalResults.length,
    userStartIndex,
  ]);

  useEffect(() => {
    setHovered(totalResults[cursor]);
  }, [cursor, totalResults, hovered]);

  const handleKeyPress = () => {};

  useEffect(() => {
    window.addEventListener("keypress", handleKeyPress);
    return () => {
      window.removeEventListener("keypress", handleKeyPress);
    };
  }, []);

  const search = useThrottle(
    (term) => {
      if (!term) return;
      setSearching(true);
      api.search.omni(
        { query: term },
        (results) => {
          setOpen(true);
          setSearching(false);
          setSearchResults(results);
        },
        () => setSearching(false),
      );
    },
    1000,
    [],
  );

  const updateSearchTerm = (e) => {
    const term = e.target.value;
    if (term[term.length - 1] !== "/") setSearchTerm(e.target.value);
    if (term) {
      setSearching(true);
      search(e.target.value);
      setSpotlightResults(getPath(user, term));
    } else {
      setSearching(false);
    }
  };

  useEffect(() => {
    if (cursor > totalResults.length) setCursor(totalResults.length - 1);
    if (totalResults.length) setOpen(true);
  }, [cursor, totalResults.length]);

  const pathName = location?.pathname;
  useEffect(() => {
    setOpen(false);
  }, [pathName]);

  const userIsAdmin = util.hasPermission(user, "org.viewDashboard", user && user?.organisation?._id);
  const hasSearchResults = useMemo(() => !searchResultsAreEmpty(searchResults), [searchResults]);

  const hoverControls = (index) => ({
    onMouseEnter: () => setCursor(index),
    // onMouseLeave: () => setCursor(0),
  });

  return (
    <StyledSearchBar>
      <Popup
        basic
        on="focus"
        hideOnScroll={false}
        position="bottom right"
        offset={[0, theme.sizes.isMobile ? -10 : -2]}
        open={open}
        onClose={() => setOpen(false)}
        style={{ maxHeight: 800, width: theme.sizes.isMobile ? "100vw" : "fit-content" }}
        trigger={
          <Input
            autoFocus={theme.sizes.isMobile}
            value={searchTerm}
            icon="search"
            iconPosition="left"
            style={{ border: "0px" }}
            size="small"
            placeholder={!userIsAdmin ? `Search ${util.appName()}...` : "Find users and more..."}
            onChange={updateSearchTerm}
            loading={searching}
            onClick={inputClicked}
            ref={searchInput}
          />
        }
        content={
          searchTerm ? (
            !hasSearchResults && !spotlightResults.length && !searching ? (
              <NoSearchContainer $width={width}>
                <h4 style={{ marginBottom: 10 }}>No results found</h4>
                <p>Try adjusting your search query or check your spelling.</p>
              </NoSearchContainer>
            ) : (
              <StyledSearchResults onClick={onClose} $width={width}>
                <Grid stackable>
                  {spotlightResults.length ? (
                    <StyledTitleRow $spaceTop>
                      <StyledTitle>Pages</StyledTitle>
                    </StyledTitleRow>
                  ) : null}
                  {spotlightResults.map((result, i) => (
                    <StyledSpotlightResult
                      key={result.title}
                      $active={cursor === i}
                      as={Link}
                      to={result.link}
                      {...hoverControls(i)}
                    >
                      <Icon name={result.icon} style={{ marginRight: 5 }} />
                      <p>
                        <b>{result.title}</b> - {result.link}
                      </p>
                    </StyledSpotlightResult>
                  ))}
                  {searchResults.users && searchResults.users.length > 0 ? (
                    <Grid.Column width={8} style={{ marginTop: 0 }}>
                      <h4>People</h4>
                      {searchResults.users.map((searchUser, index) => (
                        <UserSearchItem
                          active={index + userStartIndex === cursor}
                          key={searchUser._id}
                          user={searchUser}
                          onClick={() => addRecentSearch("user", searchUser.profile.fullName)}
                          {...hoverControls(index + userStartIndex)}
                        />
                      ))}
                    </Grid.Column>
                  ) : null}

                  {searchResults.challenges && searchResults.challenges.length > 0 ? (
                    <Grid.Column width={8} style={{ marginTop: 0 }}>
                      <h4>{t("common:capitalise", { key: "generic.challenges" })}</h4>
                      <List>
                        {searchResults.challenges.map((challenge, index) => (
                          <StyledSearchItem
                            $active={index + challengeStartIndex === cursor}
                            as={Link}
                            to={`/challenges/${challenge._id}`}
                            key={challenge._id}
                            onClick={() => addRecentSearch("challenge", challenge.name)}
                            {...hoverControls(index + challengeStartIndex)}
                          >
                            <Icon name="target" />
                            <span>{challenge.name}</span>
                          </StyledSearchItem>
                        ))}
                      </List>
                    </Grid.Column>
                  ) : null}

                  {searchResults.ideas && searchResults.ideas.length > 0 ? (
                    <Grid.Column width={8}>
                      <h4>{t("common:capitalise", { key: "generic.ideas" })}</h4>
                      <List>
                        {searchResults.ideas.map((idea, index) => (
                          <StyledSearchItem
                            $active={index + ideaStartIndex === cursor}
                            as={Link}
                            to={`/ideas/${idea._id}`}
                            key={idea._id}
                            onClick={() => addRecentSearch("idea", idea.name)}
                            {...hoverControls(index + ideaStartIndex)}
                          >
                            <Icon name="lightbulb" />
                            <span>{idea.name}</span>
                          </StyledSearchItem>
                        ))}
                      </List>
                    </Grid.Column>
                  ) : null}

                  {searchResults.groups && searchResults.groups.length > 0 ? (
                    <Grid.Column width={8}>
                      <h4>Groups</h4>
                      <List>
                        {searchResults.groups.map((group, index) => (
                          <StyledSearchItem
                            $active={index + groupStartIndex === cursor}
                            as={Link}
                            to={`/groups/${group._id}`}
                            key={group._id}
                            onClick={() => addRecentSearch("group", group.name)}
                            {...hoverControls(index + groupStartIndex)}
                          >
                            <Icon name="users" />
                            <span>{group.name}</span>
                          </StyledSearchItem>
                        ))}
                      </List>
                    </Grid.Column>
                  ) : null}

                  {searchResults.organisations && searchResults.organisations.length > 0 ? (
                    <Grid.Column width={8}>
                      <h4>Organisations</h4>
                      <List>
                        {searchResults.organisations.map((org, index) => (
                          <StyledSearchItem
                            $active={index + organisationStartIndex === cursor}
                            as={Link}
                            to={"#"}
                            key={org._id}
                            onClick={(e) => {
                              addRecentSearch("organisation", org.name);
                              switchOrganisation(e, org);
                            }}
                            {...hoverControls(index + organisationStartIndex)}
                          >
                            <Icon name="sitemap" />
                            <span>{org.name}</span>
                          </StyledSearchItem>
                        ))}
                      </List>
                    </Grid.Column>
                  ) : null}
                  {searchResults.tags && searchResults.tags.length > 0 ? (
                    <Grid.Column width={8}>
                      <h4>Tags</h4>
                      <List>
                        {searchResults.tags.map((tag, index) => (
                          <StyledSearchItem
                            $active={index + tagStartIndex === cursor}
                            as={Link}
                            to={`/tags/${tag._id}`}
                            key={tag._id}
                            onClick={() => {
                              addRecentSearch("tag", tag.value);
                            }}
                            {...hoverControls(index + tagStartIndex)}
                          >
                            <Icon name="hashtag" />
                            <span>{tag.value}</span>
                          </StyledSearchItem>
                        ))}
                      </List>
                    </Grid.Column>
                  ) : null}
                </Grid>
              </StyledSearchResults>
            )
          ) : (
            <NoSearchContainer $width={width}>
              {recentSearches && recentSearches.length > 0 ? (
                <>
                  <h4 style={{ marginBottom: 10 }}>Recent searches</h4>
                  {recentSearches.slice(0, 5).map((sr, index) => (
                    <StyledSearchItem
                      key={index}
                      as={Link}
                      onClick={() => {
                        setSpotlightResults([]);
                        setSearchResults({});
                        updateSearchTerm({ target: { value: sr.search } });
                      }}
                      $active={cursor === index}
                      {...hoverControls(index)}
                    >
                      <Icon name="search" />
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <span>{sr.search}</span>
                        <span style={{ color: "rgba(0,0,0,0.3)", textTransform: "capitalize" }}>{sr.type}</span>
                      </div>
                    </StyledSearchItem>
                  ))}
                </>
              ) : (
                <>
                  <h4 style={{ marginBottom: 10 }}>Nothing here yet</h4>
                  <p>Search for users, challenges, ideas, groups, organisations, and tags to see them here. </p>
                </>
              )}
            </NoSearchContainer>
          )
        }
      />
    </StyledSearchBar>
  );
}

const mapStateToProps = (state) => ({ user: state.user });
const mapDispatchToProps = (dispatch) => ({
  onSwitchOrganisation: (org) => dispatch(actions.user.switchOrganisation(org)),
});
const OmniBarContainer = connect(mapStateToProps, mapDispatchToProps)(OmniBar);

export default OmniBarContainer;
