import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import api from "api";
import util from "utils/utils";
import moment from "moment";
import useTheme from "theme/useTheme";
import { useTranslation } from "react-i18next";

import { Icon, Loader, Container, Grid, Input } from "semantic-ui-react";
import { SideNav } from "components/lib/SideNav";
import { Banner } from "components/lib/UI";
import RowComponent from "components/lib/RowComponent";
import TagModalChooser from "components/lib/Choosers/Tags/TagModalChooser";

import NotFound from "components/NotFound";

const Tags = ({ user }) => {
  const navigate = useNavigate();

  const params = useParams();
  const tagIds = params.ids?.split(",") || [];
  const allTags = user.organisation.tagList;
  const theme = useTheme();

  const [loading, setLoading] = useState(false);
  const [tagData, setTagData] = useState({ tags: [] });
  const currentTags = tagData.tags;
  const [searchValue, setSearchValue] = useState("");

  const { t } = useTranslation();

  useEffect(() => {
    const _tagIds = params.ids?.split(",") || [];
    setLoading(true);
    if (_tagIds.length > 0) {
      api.tags.full(_tagIds, (newTagData) => {
        setTagData(newTagData);
        setLoading(false);
      });
    } else {
      setTagData({ tags: [], collections: {} });
      setLoading(false);
    }
  }, [params.ids]);

  if (!loading && !tagData) {
    return <NotFound />;
  }

  const { collections = {} } = tagData || {};
  const { ideas = [], challenges = [], users = [] } = collections;
  const combinedLength = Object.values(collections).reduce((p, c) => p + c.length, 0);

  return (
    <div>
      <Helmet title={"Tags"} />
      <>
        <Banner marginless>
          <Container style={{ paddingTop: 10, height: theme.sizes.isMobile && 100 }}>
            <Grid stackable>
              <Grid.Column computer={8}>
                <h1 style={{ color: "#3b3b3b", marginBottom: 2 }}>Tag explorer</h1>
                {tagIds.length && !theme.sizes.isMobile ? (
                  <p style={{ marginBottom: theme.sizes.isMobile && "-20px" }}>
                    Results for{" "}
                    {tagData?.tags
                      ?.slice(0, 3)
                      .map((t) => `"${t.value}"`)
                      .join(", ")}{" "}
                    {tagIds.length > 3 ? `and ${tagIds.length - 3} other(s).` : ""}
                  </p>
                ) : (
                  <p style={{ marginBottom: theme.sizes.isMobile && "-20px" }}>Find all tagged documents</p>
                )}
              </Grid.Column>
            </Grid>
          </Container>
        </Banner>
        <SideNav
          nav={
            <div style={{ marginTop: 10, padding: 15 }}>
              <div style={{ marginBottom: 25 }}>
                <h4>Search</h4>
                <Input
                  icon="search"
                  iconPosition="left"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  placeholder="Search..."
                />
              </div>
              <TagModalChooser
                tags={currentTags}
                availableTags={allTags}
                addTag={(t) => {
                  const updatedTags = [...tagIds, t._id];
                  navigate(`/tags/${updatedTags.join(",")}`);
                }}
                removeTag={(t) => {
                  const index = tagIds.findIndex((ti) => ti === t._id);
                  if (index !== -1) {
                    const updatedTags = [...tagIds];
                    updatedTags.splice(index, 1);
                    navigate(`/tags/${updatedTags.join(",")}`);
                  }
                }}
              />
            </div>
          }
          main={
            <>
              {loading && <Loader active inline="centered" />}
              {!loading && !tagIds.length ? (
                <>
                  <h1>No tags selected</h1>
                  <p>Use the sidebar filter to the right to search through tagged items</p>
                </>
              ) : null}
              {!loading && tagIds.length && combinedLength === 0 ? (
                <>
                  <h1>No results</h1>
                  <p>Sorry, but we could not find any matching results.</p>
                </>
              ) : null}
              {!loading && combinedLength !== 0 ? (
                <>
                  <RowComponent>
                    <h1>Ideas ({ideas.length})</h1>
                    {ideas
                      .filter((idea) => idea.name.toLowerCase().includes(searchValue.toLowerCase()))
                      .map((idea, index) => (
                        <RowComponent.Cell index={index} key={idea._id} as={Link} to={`/ideas/${idea._id}`}>
                          <div
                            style={{
                              width: 50,
                              height: 40,
                              borderRadius: 3,
                              backgroundSize: "cover",
                              backgroundPosition: "center center",
                              backgroundImage: `url(${util.mixinCssUrlFallback(util.ideaCoverImage(idea), util.ideaCoverImage())})`,
                            }}
                          >
                            {idea.isSubmitted && (
                              <Icon name="check circle" color="green" style={{ top: -5, right: -5 }} />
                            )}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginLeft: 5,
                              marginRight: 5,
                              position: "relative",
                              top: -1,
                            }}
                          >
                            <RowComponent.Title style={{ color: "#5d5d5d" }} block>
                              {idea.name}
                            </RowComponent.Title>
                            <RowComponent.StatusArea>
                              {idea.isSubmitted ? (
                                <>
                                  <RowComponent.StatusLabel color="green" />
                                  Submitted {moment(idea.submittedAt).fromNow()}
                                </>
                              ) : (
                                <>
                                  <RowComponent.StatusLabel color="blue" />
                                  Updated {moment(idea.updatedAt).fromNow()}
                                </>
                              )}
                            </RowComponent.StatusArea>
                          </div>
                        </RowComponent.Cell>
                      ))}
                  </RowComponent>
                  <RowComponent>
                    <h1>
                      {t("common:capitalise", { key: "generic.challenges" })} ({challenges.length})
                    </h1>
                    {challenges
                      .filter((challenge) => challenge.name.toLowerCase().includes(searchValue.toLowerCase()))
                      .map((challenge, index) => (
                        <RowComponent.Cell
                          index={index}
                          key={challenge._id}
                          as={Link}
                          to={`/challenges/${challenge._id}`}
                        >
                          <div
                            style={{
                              width: 50,
                              height: 40,
                              borderRadius: 3,
                              backgroundSize: "cover",
                              backgroundPosition: "center center",
                              backgroundImage: `url(${util.mixinCssUrlFallback(util.challengeImage(challenge), util.challengeImage())})`,
                            }}
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginLeft: 5,
                              marginRight: 5,
                              position: "relative",
                              top: -1,
                            }}
                          >
                            <RowComponent.Title style={{ color: "#5d5d5d" }} block>
                              {challenge.name}
                            </RowComponent.Title>
                            <RowComponent.StatusArea>
                              {challenge.stage === "closed" ? (
                                <>
                                  <RowComponent.StatusLabel color="red" /> Closed
                                </>
                              ) : (
                                challenge.stage === "published" &&
                                (challenge.deadline ? (
                                  <>
                                    <RowComponent.StatusLabel color="blue" /> Closes{" "}
                                    {moment(challenge.deadline).fromNow()}
                                  </>
                                ) : (
                                  <>
                                    <RowComponent.StatusLabel color="green" /> Open
                                  </>
                                ))
                              )}
                            </RowComponent.StatusArea>
                          </div>
                        </RowComponent.Cell>
                      ))}
                  </RowComponent>
                  {util.hasPermission(user, "org.viewDashboard", user.organisation._id) ? (
                    <RowComponent noBorder>
                      <h1>Users ({users.length})</h1>
                      {users
                        .filter((filterUser) =>
                          (filterUser.profile.fullName || "").toLowerCase().includes(searchValue.toLowerCase()),
                        )
                        .map((filterUser, index) => (
                          <RowComponent.Cell
                            index={index}
                            key={filterUser._id}
                            as={Link}
                            to={`/users/${filterUser._id}`}
                          >
                            <div
                              style={{
                                width: 50,
                                height: 40,
                                borderRadius: 3,
                                backgroundSize: "cover",
                                backgroundPosition: "center center",
                                backgroundImage: `url(${util.mixinCssUrlFallback(util.avatarUrl(filterUser), util.avatarUrl())})`,
                              }}
                            />
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                marginLeft: 5,
                                marginRight: 5,
                                position: "relative",
                                top: -1,
                              }}
                            >
                              <RowComponent.Title style={{ color: "#5d5d5d" }} block>
                                {filterUser.profile.fullName}
                              </RowComponent.Title>
                              <RowComponent.StatusArea>
                                {moment(Date.now()).diff(filterUser.lastSeenAt, "months") >= 1 ? (
                                  <>
                                    <RowComponent.StatusLabel color="red" />
                                    {moment(filterUser.lastSeenAt).fromNow()}
                                  </>
                                ) : (
                                  <>
                                    <RowComponent.StatusLabel color="green" />
                                    {moment(filterUser.lastSeenAt).fromNow()}
                                  </>
                                )}
                              </RowComponent.StatusArea>
                            </div>
                          </RowComponent.Cell>
                        ))}
                    </RowComponent>
                  ) : null}
                </>
              ) : null}
            </>
          }
        />
      </>
    </div>
  );
};

const mapStateToProps = (state) => ({ user: state.user });

export default connect(mapStateToProps)(Tags);
