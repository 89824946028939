import React, { useState, useEffect, useCallback, SetStateAction, Dispatch } from "react";
import { Divider, Input, Image, Checkbox } from "semantic-ui-react";
import util from "utils/utils";
import { Other } from "simplydo/interfaces";
import ConfigurableTable from "components/lib/ConfigurableTable";
import toast from "react-hot-toast";
import api from "api";
import styled from "styled-components";

type RoleEditorPermissionsProps = {
  forType: "organisation" | "group" | "global" | "ideaBusinessProfile";
  permissionOptions: { key: string; text: string; title: string }[];
  forId: string;
  usingRole: Other.IRole;
  setRoles: Dispatch<SetStateAction<Other.IRole[]>>;
};

export const PermissionDiv = styled.div`
  margin-bottom: 12px;
  display: flex;
  align-items: center;
`;
export const PermissionDetails = styled.div`
  display: inline-block;
  width: 70%;
`;
export const PermissionTitle = styled.p`
  margin: 2px auto;
  font-weight: bold;
  margin-bottom: 0;
`;
export const PermissionMeta = styled.p`
  margin: 2px auto;
  font-size: 13px;
  color: rgb(200, 200, 200);
`;
export const PermissionCheckbox = styled.div`
  width: 15% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -1%;
`;

const RoleEditorPermissions = ({
  forType,
  forId,
  permissionOptions,
  setRoles,
  usingRole,
}: RoleEditorPermissionsProps) => {
  const usingForId = forType === "global" ? "global" : forId;

  // Global roles can specify the organisations they're being used for, when the role is just specifically about ghosting
  const showOrgSelector = forType === "global" && !usingRole?.preventUserEditing;
  const [orgState, setOrgState] = useState<{
    loading: boolean;
    organisations: Other.IOrganisation[];
    page: number;
    query: string;
    total: number;
    nextPageAvailable: boolean;
    previousPageAvailable: boolean;
  }>({
    loading: false,
    organisations: [],
    page: 1,
    query: "",
    total: 0,
    nextPageAvailable: false,
    previousPageAvailable: false,
  });

  // Functions for handling org selection
  const fetchOrganisations = useCallback(() => {
    if (showOrgSelector) {
      setOrgState((prevOrgState) => ({ ...prevOrgState, loading: true }));
      api.organisations.getAll(
        {
          page: orgState.page,
          query: orgState.query,
          selectedTags: [],
          selectedTypes: [],
        },
        (data) => {
          setOrgState((prevOrgState) => ({
            ...prevOrgState,
            loading: false,
            organisations: data.organisations,
            total: data.total,
            nextPageAvailable: data.nextPageAvailable,
            previousPageAvailable: data.previousPageAvailable,
          }));
        },
        () => {
          setOrgState((prevOrgState) => ({ ...prevOrgState, loading: false }));
        },
      );
    }
  }, [orgState.page, orgState.query, showOrgSelector]);

  useEffect(() => {
    fetchOrganisations();
  }, [fetchOrganisations]);

  const onSelectOrg = useCallback(
    (organisation) => {
      const organisationId = organisation._id;
      const currentRoleBeingEdited = usingRole;
      if (!currentRoleBeingEdited) return;
      const currentRoleGlobalForOrgIds = [...(currentRoleBeingEdited?.scopes?.organisations ?? [])];
      const currentRoleGLobalForOrgs = [...(currentRoleBeingEdited?.ownerScopes?.organisations ?? [])];
      const orgIsSelected = currentRoleGlobalForOrgIds.includes(organisationId);
      let updatedGlobalForOrgIds = [];
      let updatedGlobalForOrgs = [];
      if (orgIsSelected) {
        updatedGlobalForOrgIds = currentRoleGlobalForOrgIds.filter((orgId) => orgId !== organisationId);
        updatedGlobalForOrgs = currentRoleGLobalForOrgs.filter((org) => org._id !== organisationId);
      } else {
        updatedGlobalForOrgIds = [...currentRoleGlobalForOrgIds, organisationId];
        updatedGlobalForOrgs = [...currentRoleGLobalForOrgs, organisation];
      }
      setRoles((prevRoles) =>
        prevRoles.map((role) =>
          role._id === currentRoleBeingEdited._id
            ? {
                ...role,
                scopes: {
                  ...(role?.scopes ?? {}),
                  organisations: updatedGlobalForOrgIds,
                },
                ownerScopes: {
                  ...(role?.ownerScopes ?? {}),
                  organisations: updatedGlobalForOrgs,
                },
              }
            : role,
        ),
      );
      api.roles.updateRole(
        forType,
        usingForId,
        currentRoleBeingEdited._id,
        {
          scopes: {
            organisations: updatedGlobalForOrgIds,
          },
        },
        () => {},
        (err) => {
          toast.error(err.message);
          setRoles((prevRoles) =>
            prevRoles.map((role) =>
              role._id === currentRoleBeingEdited._id
                ? {
                    ...role,
                    scopes: {
                      ...(role?.scopes ?? {}),
                      organisations: currentRoleGlobalForOrgIds,
                    },
                    ownerScopes: {
                      ...(role?.ownerScopes ?? {}),
                      organisations: currentRoleGLobalForOrgs,
                    },
                  }
                : role,
            ),
          );
        },
      );
    },
    [usingRole, setRoles, forType, usingForId],
  );

  // Regular role editor
  const getPermissionDetail = useCallback(
    (permission: string) => permissionOptions.find((perm) => perm.key === permission),
    [permissionOptions],
  );

  const addPermissionsToRole = useCallback(
    (roleId: string, permissions: string[]) => {
      api.roles.addPermissionsToRole(
        forType,
        usingForId,
        roleId,
        permissions,
        ({ role: updatedRole }) => {
          setRoles((prevRoles) =>
            prevRoles.map((role) => (role._id === updatedRole._id ? { ...role, ...updatedRole } : role)),
          );
        },
        (err) => {
          toast.error(err.message);
        },
      );
    },
    [forType, setRoles, usingForId],
  );

  const removePermissionsFromRole = useCallback(
    (roleId: string, permissions: string[]) => {
      api.roles.removePermissionsFromRole(
        forType,
        usingForId,
        roleId,
        permissions,
        ({ role: updatedRole }) => {
          setRoles((prevRoles) =>
            prevRoles.map((role) => (role._id === updatedRole._id ? { ...role, ...updatedRole } : role)),
          );
        },
        (err) => {
          toast.error(err.message);
        },
      );
    },
    [forType, setRoles, usingForId],
  );

  const removeSinglePermissionFromRole = useCallback(
    (roleId: string, permission: string) => {
      const permissionDetail = getPermissionDetail(permission);
      util
        .confirm(
          `Removing ${permissionDetail.title} from ${usingRole.name}`,
          "Are you sure you want to remove this permission from this role? You are able to add it back at any time.",
        )
        .then(() => {
          removePermissionsFromRole(roleId, [permission]);
        })
        .catch(() => {});
    },
    [getPermissionDetail, removePermissionsFromRole, usingRole],
  );

  const toggleIndividualPermission = useCallback(
    (permission: string) => {
      if (!usingRole) return;
      if (usingRole.permissions.includes(permission)) {
        removeSinglePermissionFromRole(usingRole._id, permission);
      } else {
        addPermissionsToRole(usingRole._id, [permission]);
      }
    },
    [addPermissionsToRole, usingRole, removeSinglePermissionFromRole],
  );

  return (
    <>
      {permissionOptions.map((permission) => (
        <PermissionDiv key={permission.key}>
          <PermissionCheckbox>
            <Checkbox
              disabled={usingRole?.preventUserEditing}
              toggle
              checked={usingRole?.permissions.includes(permission.key)}
              onChange={() => toggleIndividualPermission(permission.key)}
            />
          </PermissionCheckbox>
          <PermissionDetails>
            <PermissionTitle>{permission.title}</PermissionTitle>
            <PermissionMeta>{permission.text}</PermissionMeta>
          </PermissionDetails>
        </PermissionDiv>
      ))}

      {showOrgSelector ? (
        <div>
          <Divider hidden />
          <h5>Limit to specific organisations</h5>
          <p>
            Choose organisations that these permissions should be restricted to. Leave these empty to allow unrestricted
            superadmin access.
          </p>

          <Input
            placeholder="Search for organisations by name/code.."
            value={orgState.query}
            onChange={(e) => setOrgState((prevOrgState) => ({ ...prevOrgState, query: e.target.value }))}
            fluid
          />

          <ConfigurableTable
            onSelect={(org) => onSelectOrg(org)}
            // @ts-ignore
            selectedKeys={usingRole?.scopes?.organisations ?? []}
            tableKey="superadmin-organisations"
            data={orgState.organisations}
            keyExtractor={(org) => org._id}
            preventSelectAll
            columns={[
              {
                key: "darkLogoUrl",
                name: "",
                settingName: "Image",
                center: true,
                width: 40,
                render: ({ cell }) => <Image style={{ objectFit: "contain" }} avatar src={cell} />,
              },
              {
                key: "name",
                name: `Organisation (${orgState.total})`,
              },
              {
                key: "code",
                name: "Code",
                render: ({ cell }) => (
                  <span
                    style={{
                      background: "#efefef",
                      fontSize: "11px",
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                      borderRadius: 3,
                      padding: "0 3px",
                    }}
                  >
                    {cell}
                  </span>
                ),
              },
            ]}
          />
        </div>
      ) : null}
    </>
  );
};

export default RoleEditorPermissions;
