import React, { useMemo } from "react";
import { Menu } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { Routes, Route, useLocation, Link } from "react-router-dom";
import { Other } from "simplydo/interfaces";
import util from "utils/utils";

import AssessmentsDashboard from "./AssessmentsDashboard";
import YourAssessments from "./YourAssessments";
import Assignment from "./Assignment";

type IChallengeAssessments = {
  challenge: Other.IChallenge;
  getAssignedAssessments: () => void;
  myAssignedAssessments: Other.IIdeaAssignment[];
  setMyAssignedAssessments: (assessments: Other.IIdeaAssignment[]) => void;
  demoingTour?: string;
};

const ChallengeAssessments = (props: IChallengeAssessments) => {
  const user = useSelector((state: { user: Other.IUserMe | null }) => state?.user);
  const canView = useMemo(() => util.canViewEntireChallenge(user, props.challenge), [user, props.challenge]);
  const canManage = useMemo(() => util.canManageChallenge(user, props.challenge), [user, props.challenge]);
  const { pathname } = useLocation();
  const challengeId = props?.challenge?._id;

  return (
    <div>
      {canView ? (
        <Menu pointing secondary>
          <Menu.Item
            content="Dashboard"
            as={Link}
            to="."
            active={pathname === `/challenges/${challengeId}/assessments`}
          />
          {canManage ? (
            <Menu.Item
              content="Assignment"
              as={Link}
              to="assignment"
              active={pathname === `/challenges/${challengeId}/assessments/assignment`}
            />
          ) : null}
          {props.myAssignedAssessments?.length ? (
            <Menu.Item
              content="Your assessments"
              as={Link}
              to="assigned"
              active={pathname === `/challenges/${challengeId}/assessments/assigned`}
            />
          ) : null}
        </Menu>
      ) : null}
      <Routes>
        <Route path="/" element={canManage ? <AssessmentsDashboard {...props} /> : <YourAssessments {...props} />} />
        <Route path="assignment" element={canManage ? <Assignment {...props} /> : <YourAssessments {...props} />} />
        <Route path="assigned" element={<YourAssessments {...props} />} />
      </Routes>
    </div>
  );
};

export default ChallengeAssessments;
