import React, { useState, useEffect, useCallback, SetStateAction, Dispatch, useMemo } from "react";
import { Input, Button } from "semantic-ui-react";
import { Other } from "simplydo/interfaces";
import { Link } from "react-router-dom";
import ConfigurableTable from "components/lib/ConfigurableTable";
import toast from "react-hot-toast";
import api from "api";
import util from "utils/utils";
import { ImageWithFallback } from "components/lib/ImageWithFallback";
import { useAppSelector } from "store";

type RoleEditorAssignmentProps = {
  forType: "organisation" | "group" | "global" | "ideaBusinessProfile";
  forId: string;
  usingRole: Other.IRole;
  setRoles: Dispatch<SetStateAction<Other.IRole[]>>;
};

const RoleEditorAssignment = ({ forType, forId, setRoles, usingRole }: RoleEditorAssignmentProps) => {
  const [usersSearchState, setUsersSearchState] = useState<{
    query: string;
    page: number;
    limit: number;
  }>({
    query: "",
    page: 1,
    limit: 10,
  });
  const [usersState, setUsersState] = useState<{
    users: Other.IUser[];
    loading: boolean;
    total: number;
    nextPageAvailable: boolean;
    prevPageAvailable: boolean;
  }>({
    users: [],
    loading: false,
    total: 0,
    nextPageAvailable: false,
    prevPageAvailable: false,
  });

  const user = useAppSelector((state) => state.user);

  const usersWithRole = useMemo(() => {
    if (!usingRole?._id) {
      return [];
    }
    return usersState.users.filter((u) => (u.roleIds ?? []).includes(usingRole._id)).map((u) => u._id);
  }, [usersState.users, usingRole?._id]);

  const usingForId = forType === "global" ? "global" : forId;

  const getUsers = useCallback(() => {
    if (!forId || !forType) return;
    setUsersState((prevSearchState) => ({ ...prevSearchState, loading: true }));
    api.roles.getPotentialRoleUsers(
      forType,
      forId,
      usingRole._id,
      { ...usersSearchState },
      (data) => {
        setUsersState({
          users: data.users,
          loading: false,
          total: data.total,
          nextPageAvailable: data.nextPageAvailable,
          prevPageAvailable: data.prevPageAvailable,
        });
      },
      (err) => {
        setUsersState((prevUsersState) => ({ ...prevUsersState, loading: false }));
        toast.error(err.message);
      },
    );
  }, [forId, forType, usersSearchState, usingRole._id]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const updateUserRoles = useCallback((userId: string, roleId: string) => {
    setUsersState((prevUsersState) => ({
      ...prevUsersState,
      users: prevUsersState.users.map((prevUser) => {
        if (prevUser._id === userId) {
          return {
            ...prevUser,
            roleIds: prevUser.roleIds?.includes(roleId)
              ? prevUser.roleIds.filter((role) => role !== roleId)
              : [...(prevUser.roleIds ?? []), roleId],
          };
        }
        return prevUser;
      }),
    }));
  }, []);

  const assignRoleToUser = useCallback(
    (userId: string) => {
      api.roles.assignRole(
        forType,
        usingForId,
        usingRole._id,
        [userId],
        () => {
          toast.success(`Role ${usingRole.name} assigned to user`);
          updateUserRoles(userId, usingRole._id);
          setRoles((prevRoles) =>
            prevRoles.map((role) => {
              if (role._id === usingRole._id) {
                return {
                  ...role,
                  userCount: (role.userCount ?? 0) + 1,
                };
              }
              return role;
            }),
          );
        },
        (err) => {
          toast.error(err.message);
        },
      );
    },
    [forType, setRoles, updateUserRoles, usingForId, usingRole._id, usingRole.name],
  );

  const unassignRoleFromUser = useCallback(
    (userId: string) => {
      api.roles.unassignRole(
        forType,
        usingForId,
        usingRole._id,
        [userId],
        () => {
          toast.success(`Role ${usingRole?.name} unassigned from user`);
          updateUserRoles(userId, usingRole._id);
          setRoles((prevRoles) =>
            prevRoles.map((role) => {
              if (role._id === usingRole._id) {
                return {
                  ...role,
                  userCount: (role.userCount ?? 0) - 1,
                };
              }
              return role;
            }),
          );
        },
        (err) => {
          toast.error(err.message);
        },
      );
    },
    [forType, setRoles, updateUserRoles, usingForId, usingRole._id, usingRole?.name],
  );

  const onSelectUser = useCallback(
    (selectedUser) => {
      const userHasRole = selectedUser.roleIds?.includes(usingRole._id);
      if (userHasRole) {
        if (user._id === selectedUser._id) {
          util
            .confirm(
              "Unassign yourself",
              "Are you sure you want to unassign yourself from this role? This might result in you losing access to this resource.",
            )
            .then(() => {
              unassignRoleFromUser(selectedUser._id);
            })
            .catch(() => {});
        } else {
          unassignRoleFromUser(selectedUser._id);
        }
      } else {
        assignRoleToUser(selectedUser._id);
      }
    },
    [assignRoleToUser, unassignRoleFromUser, usingRole._id, user._id],
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
      }}
    >
      <Input
        icon="search"
        placeholder="Search users..."
        value={usersSearchState.query}
        fluid
        onChange={(e) => setUsersSearchState((prevSearchState) => ({ ...prevSearchState, query: e.target.value }))}
        loading={usersState.loading}
        style={{ marginBottom: 10 }}
      />
      <ConfigurableTable
        tableKey="roleEditorAssignment"
        data={usersState.users}
        keyExtractor={(user) => user._id}
        selectedKeys={usersWithRole}
        onSelect={onSelectUser}
        preventSelectAll
        columns={[
          {
            key: "image",
            name: "",
            settingName: "Image",
            width: 40,
            center: true,
            render: ({ item }) => (
              <Link to={`/users/${item._id}`}>
                <ImageWithFallback
                  style={{ objectFit: "cover" }}
                  avatar
                  src={util.avatarUrl(item)}
                  fallbackSrc={util.avatarUrl()}
                />
              </Link>
            ),
          },
          {
            key: "firstName",
            name: "First name",
            render: ({ item }) => <Link to={`/users/${item._id}`}>{item.profile.firstName}</Link>,
            sortable: true,
          },
          {
            key: "lastName",
            name: "Last name",
            render: ({ item }) => <Link to={`/users/${item._id}`}>{item.profile.lastName}</Link>,
            sortable: true,
          },
          {
            key: "email",
            name: "Email",
            render: ({ item }) => (item.emails?.length ? item.emails[0].address : ""),
            sortable: false,
          },
          {
            key: "department",
            name: "Department",
            render: ({ item }) => item.profile.department,
            sortable: false,
          },
        ]}
      />

      {usersState.prevPageAvailable || usersState.nextPageAvailable ? (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 10,
          }}
        >
          {usersState.prevPageAvailable ? (
            <Button
              size="small"
              content="Previous page"
              onClick={() =>
                setUsersSearchState((prevSearchState) => ({ ...prevSearchState, page: usersSearchState.page - 1 }))
              }
            />
          ) : (
            <div />
          )}

          {usersState.nextPageAvailable ? (
            <Button
              size="small"
              content="Next page"
              onClick={() =>
                setUsersSearchState((prevSearchState) => ({ ...prevSearchState, page: usersSearchState.page + 1 }))
              }
            />
          ) : (
            <div />
          )}
        </div>
      ) : null}
    </div>
  );
};

export default RoleEditorAssignment;
