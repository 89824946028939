import React, { useState, useEffect, useCallback } from "react";
import { Button, Icon, Popup, Message } from "semantic-ui-react";
import { OpenAPI, Other } from "simplydo/interfaces";
import styled from "styled-components";
import toast from "react-hot-toast";
import api from "api";

import utils from "utils/utils";
import BusinessProfileSelector from "./ProfileSelector";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store";
import { Overview } from "components/lib/IdeaBusinessProfileModal/Overview";
import IdeaBusinessProfileModal from "components/lib/IdeaBusinessProfileModal/IdeaBusinessProfileModal";
import { BusinessProfileSearcher } from "./BusinessProfileSearcher";

const IdeaSection = styled.div`
  > h5 {
    display: block;
    margin: 0;
  }
  > .title-explainer {
    color: gray;
    display: block;
    margin: 2.5px 0 20px;
  }
`;

type IIdeaBusinessProfileComponent = {
  idea: Other.IIdea;
  isEditing?: boolean;
  style?: object;
  updateIdea: (updatedValue: any, path: string[], suppressSave?: boolean) => void;
};

const IdeaBusinessProfileComponent = ({ idea, isEditing, style, updateIdea }: IIdeaBusinessProfileComponent) => {
  // Track state of the profile editor - what stage the user is at
  const [creditsafeEnabled, setCreditsafeEnabled] = useState(false);
  // The user's existing (previously created) organisation profiles
  const [loadingExistingProfiles, setLoadingExistingProfiles] = useState(false);
  const [existingProfiles, setExistingProfiles] = useState<OpenAPI.Schemas["IdeaBusinessProfile"][]>([]);

  const [generatingCreditReport, setGeneratingCreditReport] = useState(false);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [isCreationModalOpen, setIsCreationModalOpen] = useState(false);

  const ideaBusinessProfile = idea["lockedOwnerIdeaBusinessProfile"];
  const [businessProfile, setBusinessProfile] = useState<OpenAPI.Schemas["IdeaBusinessProfile"]>(ideaBusinessProfile);
  const { profile, creditsafeReportUrl } = businessProfile || {};
  const {
    name,
    address = {},
    creditsafeRegNo,
    vatNo,
    phoneNumber,
    creditsafeCompanyId,
    primaryContact,
    websiteUrl,
    websiteUrlSkipped,
    type,
    status,
    size,
    vatNoSkipped,
    creditsafeRegNoSkipped,
  } = profile || {};
  const { phoneNumber: contactPhoneNumber, email, fullName, jobTitle } = primaryContact || {};
  const { country, city, streetAddress, postcode } = address || {};

  const user = useAppSelector((state) => state.user);
  const { t } = useTranslation();

  const challengeId = idea?.challenge;
  const hasReportPermissions = utils.canManageChallenge(user, idea.ownerChallenge) || idea.isAssessor;

  useEffect(() => {
    api.challenges.get(
      challengeId,
      (challenge) => {
        setCreditsafeEnabled(challenge.creditsafeAvailable);
      },
      () => {
        setCreditsafeEnabled(false);
      },
    );
  }, [challengeId]);

  // Attach a new, or previously created organisation profile
  // Once done, update the idea locally and display the preview of the organisation profile
  const attachBusinessProfile = useCallback(
    (ideaBusinessProfile) => {
      setBusinessProfile(ideaBusinessProfile);
      updateIdea(ideaBusinessProfile._id, ["ideaBusinessProfile"]);
      updateIdea(ideaBusinessProfile, ["lockedOwnerIdeaBusinessProfile"], true);
    },
    [updateIdea],
  );

  const unattachBusinessProfile = useCallback(() => {
    updateIdea(null, ["ideaBusinessProfile"]);
    updateIdea(null, ["lockedOwnerIdeaBusinessProfile"], true);
    setBusinessProfile(null);
  }, [updateIdea]);

  const viewReportForCompany = useCallback(() => {
    if (ideaBusinessProfile?._id) {
      setGeneratingCreditReport(true);
      api.users.generateIdeaBusinessProfileCreditReport(
        ideaBusinessProfile?._id,
        ({ success, creditsafeReportUrl }) => {
          if (!success) {
            toast.error("Could not generate CreditSafe report");
            setGeneratingCreditReport(false);
            return;
          }
          updateIdea(creditsafeReportUrl, ["lockedOwnerIdeaBusinessProfile", "creditsafeReportUrl"], true);
          setGeneratingCreditReport(false);
          window.open(creditsafeReportUrl, "_blank", "noopener noreferrer");
        },
        (err) => {
          toast.error(err.message);
          setGeneratingCreditReport(false);
        },
      );
    }
  }, [ideaBusinessProfile?._id, updateIdea]);

  useEffect(() => {
    setLoadingExistingProfiles(true);
    api.users.getExistingProfiles(
      ({ profiles }) => {
        setLoadingExistingProfiles(false);
        setExistingProfiles(profiles);
      },
      (err) => {
        setLoadingExistingProfiles(false);
        toast.error(err.message);
      },
    );
  }, []);

  if (!isEditing && !ideaBusinessProfile) {
    return null;
  }

  const requiredInfoFields = [
    { label: "Name", value: name },
    { label: "Website URL", value: websiteUrl, skipped: websiteUrlSkipped },
    { label: "Phone number", value: phoneNumber },
    { label: "Status", value: status },
    { label: "Type", value: type },
    { label: "Size", value: size },
    { label: "Street address", value: streetAddress },
    { label: "Country", value: country },
    { label: "City", value: city },
    { label: "Postcode", value: postcode },
    { label: "VAT number", value: vatNo, skipped: vatNoSkipped },
    { label: "Company registration number", value: creditsafeRegNo, skipped: creditsafeRegNoSkipped },
    { label: "Contact name", value: fullName },
    { label: "Contact phone number", value: contactPhoneNumber },
    { label: "Contact email", value: email },
    { label: "Contact job title", value: jobTitle },
  ];

  const isMissingRequiredInfo = requiredInfoFields.some((field) => !field.value && !field.skipped);

  return (
    <IdeaSection style={style}>
      {isProfileModalOpen ? (
        <IdeaBusinessProfileModal
          businessProfile={businessProfile}
          onClose={() => {
            setIsProfileModalOpen(false);
            updateIdea(businessProfile._id, ["ideaBusinessProfile"]);
          }}
          onSave={(savedProfile) => {
            setBusinessProfile(savedProfile);
            setExistingProfiles((prevProfiles) => [
              ...prevProfiles.filter((p) => p._id !== savedProfile._id),
              savedProfile,
            ]);
            attachBusinessProfile(savedProfile);
          }}
          onDelete={() => {
            updateIdea(null, ["lockedOwnerIdeaBusinessProfile"], true);
            updateIdea(null, ["ideaBusinessProfile"]);
            setExistingProfiles((prevProfiles) => prevProfiles.filter((p) => p._id !== businessProfile._id));
            setIsProfileModalOpen(false);
            setBusinessProfile(null);
          }}
        />
      ) : null}
      {isCreationModalOpen ? (
        <BusinessProfileSearcher
          onClose={() => setIsCreationModalOpen(false)}
          onSelect={(profile) => {
            // @ts-ignore
            setBusinessProfile({
              profile,
            });
            setIsCreationModalOpen(false);
            setIsProfileModalOpen(true);
          }}
          creditsafeEnabled={creditsafeEnabled}
          ideaId={idea._id}
        />
      ) : null}
      <h3>
        Organisation details
        {isEditing && idea.ideaTemplate?.body?.find((section) => section.type === "businessProfile")?.isRequired ? (
          <Popup
            size="small"
            trigger={
              <Icon name="asterisk" style={{ position: "relative", top: -5, marginLeft: 3 }} color="red" size="tiny" />
            }
            content={`This field must be filled in to submit your ${t("generic.idea")}`}
          />
        ) : null}
      </h3>
      {isEditing ? (
        <span className="title-explainer">
          Please provide your organisation's details to supplement your {t("generic.idea")}.
        </span>
      ) : (
        <div style={{ marginBottom: 5 }} />
      )}

      {ideaBusinessProfile && isMissingRequiredInfo && (
        <Message
          icon="exclamation triangle"
          warning
          size="small"
          content={
            <>
              <p>
                <strong>Your organisation profile is missing required information</strong>
              </p>
              <ul>
                {requiredInfoFields.map((field) => {
                  if (field.value || field.skipped) {
                    return null;
                  }
                  return <li key={field.label}>{field.label}</li>;
                })}
              </ul>
            </>
          }
        />
      )}

      {isEditing ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 8,
            alignItems: existingProfiles ? "flex-end" : "stretch",
          }}
        >
          <BusinessProfileSelector
            key={ideaBusinessProfile?._id}
            attachedBusinessProfile={ideaBusinessProfile}
            loadingExistingProfiles={loadingExistingProfiles}
            existingProfiles={existingProfiles}
            attachBusinessProfile={(ideaBusinessProfile) => attachBusinessProfile(ideaBusinessProfile)}
            unattachBusinessProfile={unattachBusinessProfile}
          />
          {ideaBusinessProfile ? (
            <div
              style={{
                display: "flex",
                gap: 8,
                alignSelf: "flex-end",
              }}
            >
              <Button basic onClick={() => setIsProfileModalOpen(true)}>
                <Icon name="pencil" />
                Manage profile
              </Button>
            </div>
          ) : (
            <Button
              secondary={existingProfiles.length === 0}
              basic={existingProfiles.length !== 0}
              onClick={() => (creditsafeEnabled ? setIsCreationModalOpen(true) : setIsProfileModalOpen(true))}
            >
              <Icon name="plus" />
              Create new profile
            </Button>
          )}
        </div>
      ) : null}

      {ideaBusinessProfile ? (
        <div style={{ marginTop: 16 }}>
          <Overview businessProfile={ideaBusinessProfile} />
          {creditsafeCompanyId && hasReportPermissions ? (
            <div className="credit-report-area">
              {creditsafeReportUrl ? (
                <Button
                  content="Download credit report"
                  icon="file pdf"
                  as="a"
                  href={creditsafeReportUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                />
              ) : creditsafeEnabled ? (
                <Button
                  content="Download credit report"
                  icon="file pdf"
                  onClick={() => viewReportForCompany()}
                  loading={generatingCreditReport}
                />
              ) : null}
            </div>
          ) : null}
        </div>
      ) : null}
    </IdeaSection>
  );
};

export default IdeaBusinessProfileComponent;
