import React from "react";
import { Label, Form, Button, Message } from "semantic-ui-react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const AssessmentContainer = styled.div`
  padding: 15px;
  background-color: #fff;
  ${({ $floating }) =>
    $floating
      ? `
    border-radius: 5px;
  `
      : `
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  `}

  border: 1.5px solid #6435c9;
  margin: 0 -1px;
  &:not(:last-of-type) {
    margin-bottom: 20px;
  }
  ${({ $noShadow }) => !$noShadow && "box-shadow:0px 10px 10px rgba(0,0,0,0.1);"}
  ${({ $isPDF }) =>
    $isPDF &&
    `
    margin: 20px;
  `}
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  h3 {
    display: block;
    margin: ${({ $noMargin }) => ($noMargin ? "0" : "0 0 0")};
  }
  > span {
    display: block;
    color: gray;
    text-decoration-line: underline;
    font-size: 12px;
  }
  margin-bottom: 10px;
`;

export const IdeaAssessmentConflictOfInterestBox = ({
  idea,
  assessment,
  updateAssessment,
  noShadow,
  style,
  className,
  isPDF,
  submitAssessment,
}) => {
  const isSubmitted = assessment?.isSubmitted;
  const { conflictOfInterest = {} } = assessment || {};
  const { hasConflictOfInterest, notes } = conflictOfInterest;
  const hasCompletedConflictDeclaration = hasConflictOfInterest !== undefined;

  const { t } = useTranslation();
  if (idea?.isAssessor) {
    return (
      <AssessmentContainer $noShadow={noShadow} style={style} className={className} $floating $isPDF={isPDF}>
        <TitleContainer $noMargin>
          <h3>Conflict of interest</h3>
          {isSubmitted && (
            <Label size="small" color="green">
              Assessment submitted
            </Label>
          )}
        </TitleContainer>
        <p>
          If you have a conflict of interest that would prevent you from objectively assessing this {t("generic.idea")}{" "}
          please declare it now. If you declare a conflict of interest you can submit the assessment without completing
          it. Once submitted, managers of this {t("generic.challenge")} will be notified. You can edit your conflict
          declaration at any time before submitting the assessment.
        </p>
        {!hasCompletedConflictDeclaration && (
          <Message
            warning
            size="tiny"
            content="You must complete the conflict of interest declaration before you can view the assessment."
          />
        )}
        <Button
          content={`I do NOT need to declare a conflict of interest`}
          size="small"
          primary={hasCompletedConflictDeclaration && !hasConflictOfInterest}
          onClick={() => {
            updateAssessment(false, ["conflictOfInterest", "hasConflictOfInterest"]);
          }}
          disabled={isSubmitted}
        />
        <Button
          size="small"
          primary={hasCompletedConflictDeclaration && hasConflictOfInterest}
          content={`I DO need to declare a conflict of interest`}
          onClick={() => {
            updateAssessment(true, ["conflictOfInterest", "hasConflictOfInterest"]);
          }}
          disabled={isSubmitted}
        />
        {hasCompletedConflictDeclaration && hasConflictOfInterest ? (
          <>
            <p style={{ marginTop: 15, marginBottom: 8 }}>
              I would like to declare a conflict of interest for this assessment
            </p>
            <Form style={{ marginTop: 0 }}>
              <Form.TextArea
                placeholder="Please provide any further context or information.."
                disabled={isSubmitted}
                value={notes}
                onChange={(e, { value }) => updateAssessment(value, ["conflictOfInterest", "notes"])}
              />
            </Form>
            {!isSubmitted && (
              <Button style={{ marginTop: 10 }} content={"Submit assessment"} primary onClick={submitAssessment} />
            )}
          </>
        ) : null}
        {hasCompletedConflictDeclaration && !hasConflictOfInterest && (
          <Message success>
            <p>The assessor has declared that there is no conflict of interest.</p>
          </Message>
        )}
      </AssessmentContainer>
    );
  }
  return null;
};
