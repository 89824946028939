import React, { useState } from "react";
import IdeaBusinessProfileModal from "../IdeaBusinessProfileModal/IdeaBusinessProfileModal";
import BaseChip, { ChipConfiguration } from "./BaseChip";
import { OpenAPI } from "simplydo/interfaces";

const IdeaBusinessProfileChip = ({
  ideaBusinessProfile,
  ...props
}: ChipConfiguration & {
  ideaBusinessProfile: OpenAPI.Schemas["IdeaBusinessProfile"];
}) => {
  const [viewingProfile, setViewingProfile] = useState(false);

  if (!ideaBusinessProfile) {
    return null;
  }

  return (
    <>
      {viewingProfile ? (
        <IdeaBusinessProfileModal businessProfile={ideaBusinessProfile} onClose={() => setViewingProfile(false)} />
      ) : null}
      <BaseChip
        {...props}
        title={ideaBusinessProfile.profile.name}
        description={ideaBusinessProfile.profile.websiteUrl}
        imageUrl={ideaBusinessProfile.profile.imageUrl}
        icon="building"
        onClick={() => setViewingProfile(true)}
      />
    </>
  );
};

export default IdeaBusinessProfileChip;
