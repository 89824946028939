import React, { useCallback, useMemo, useState } from "react";
import { Form, Segment, Message, Input, Button, Checkbox } from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";
import { useAppSelector } from "store";
import util from "utils/utils";
import api from "api";
import toast from "react-hot-toast";
import { datePlusDays } from "./Calendar";

const Settings = ({ challenge, calendar, setCalendar }) => {
  const [shiftDays, setShiftDays] = useState(0);
  const [shiftForward, setShiftForward] = useState(true);
  const user = useAppSelector((state) => state.user);
  const canManageChallenge = util.canManageChallenge(user, challenge);
  const challengeId = challenge._id;

  const from = useMemo(() => (calendar?.start ? new Date(calendar.start) : new Date()), [calendar]);
  const nextMonth = useMemo(() => new Date(from), [from]);
  nextMonth.setMonth(nextMonth.getMonth() + 1);
  const to = useMemo(() => (calendar?.end ? new Date(calendar.end) : nextMonth), [calendar, nextMonth]);

  // Calculate min and max dates for the date pickers
  const toMinDate = datePlusDays(from, 1);
  const fromMaxDate = datePlusDays(to, -1);

  const shiftPlanner = useCallback(() => {
    api.challenges.shiftCalendarEvents(
      challengeId,
      { shiftDays, shiftForward },
      ({ calendar }) => {
        setCalendar(calendar);
        toast.success("Planner events shifted successfully");
      },
      (error) => {
        toast.error(error.message);
      },
    );
  }, [shiftDays, challengeId, shiftForward, setCalendar]);

  const setFrom = useCallback(
    (value: string) => {
      api.challenges.updateCalendar(
        challengeId,
        { start: value },
        () => {
          setCalendar((prev) => ({ ...prev, start: value }));
        },
        (error) => {
          toast.error(error.message);
        },
      );
    },
    [challengeId, setCalendar],
  );

  const setTo = useCallback(
    (value: string) => {
      api.challenges.updateCalendar(
        challengeId,
        { end: value },
        () => {
          setCalendar((prev) => ({ ...prev, end: value }));
        },
        (error) => {
          toast.error(error.message);
        },
      );
    },
    [challengeId, setCalendar],
  );
  const openDateIssue = useMemo(() => {
    return challenge?.openDate < calendar?.start || challenge?.openDate > calendar?.end;
  }, [challenge, calendar?.start, calendar?.end]);

  const closeDateIssue = useMemo(() => {
    return challenge?.deadline < calendar?.start || challenge?.deadline > calendar?.end;
  }, [challenge, calendar?.start, calendar?.end]);

  return (
    <Segment>
      <p>Manage and configure the planner.</p>
      {openDateIssue || closeDateIssue ? (
        <Message
          warning
          size="small"
          icon="warning sign"
          header="Important"
          content={`The challenge ${openDateIssue && closeDateIssue ? "open/close" : closeDateIssue ? "close" : "open"} date is currently outside of the planner date range.`}
          style={{ marginBottom: 20 }}
        />
      ) : null}
      {canManageChallenge ? (
        <div>
          <Form>
            <Form.Group>
              <Form.Field>
                <label>The calendar will span from:</label>
                <DateInput
                  value={from.toISOString().split("T")[0]}
                  dateFormat="YYYY-MM-DD"
                  onChange={(e, { value }) => setFrom(value)}
                  maxDate={fromMaxDate.toISOString().split("T")[0]}
                />
              </Form.Field>

              <Form.Field>
                <label>to:</label>
                <DateInput
                  value={to.toISOString().split("T")[0]}
                  dateFormat="YYYY-MM-DD"
                  onChange={(e, { value }) => setTo(value)}
                  minDate={toMinDate.toISOString().split("T")[0]}
                />
              </Form.Field>
            </Form.Group>
          </Form>
          <h3>Calendar shifting</h3>
          <p>
            Use this setting to shift all calendar events in an increment of days. This will include moving the
            challenge open date, close date, announcement send dates, and all calendar events.
          </p>
          <Checkbox
            toggle
            label={shiftForward ? "Shift events forward" : "Shift events backward"}
            checked={shiftForward}
            onChange={() => setShiftForward(!shiftForward)}
            style={{ display: "block", marginBottom: 15 }}
          />
          <Input
            style={{ marginRight: 5 }}
            type="number"
            onChange={(e, { value }) => setShiftDays(parseInt(value))}
            value={shiftDays}
          />
          <Button onClick={shiftPlanner} disabled={shiftDays === 0}>
            {`Shift events ${shiftForward ? "forward" : "backward"} ${util.pluralise(shiftDays, "day", "days")}`}
          </Button>
        </div>
      ) : null}
    </Segment>
  );
};

export default Settings;
